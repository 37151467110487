import { ROLE_EVALUATOR, ROLE_MASTER_RECRUITER, ROLE_RECRUITER, ROLE_HIRING_MANAGER } from 'utils/data/roles';

export const DEFAULT_ROUTE_PERMISSIONS = ['/login', '/logout', '/dashboard'];
export const ROUTE_PERMISSIONS = {
  [ROLE_MASTER_RECRUITER]: [
    '/dashboard',
    '/workflow',
    '/workflow/:workflow_id',
    '/workflow/:workflow_id/invite/:step_id',
    '/workflow/:workflow_id/drive/:drive_id',
    '/panel/decline-interview',
    '/panel/propose-new-time',
    '/panel/interviewer-dashboard',
    '/panel/accept-proposed-time',
    '/panel/calendar',
    '/panel/decline-proposed-time',
    '/panel/join-interview',
    '/panel/join-drive',
    '/job-description',
    '/evaluations',
    '/evaluations/:id',
    '/notification-inbox',
    '/organiser/reschedule',
    '/organiser/change-interviewer',
    '/dashboard/pre-interview',
  ],
  [ROLE_RECRUITER]: [
    '/dashboard',
    '/workflow',
    '/workflow/:workflow_id',
    '/workflow/:workflow_id/invite/:step_id',
    '/workflow/:workflow_id/drive/:drive_id',
    '/panel/decline-interview',
    '/panel/propose-new-time',
    '/panel/interviewer-dashboard',
    '/panel/calendar',
    `/panel/accept-proposed-time`,
    '/panel/decline-proposed-time',
    '/panel/join-interview',
    '/panel/join-drive',
    '/job-description',
    '/evaluations',
    '/evaluations/:id',
    '/notification-inbox',
    '/organiser/reschedule',
    '/organiser/change-interviewer',
    '/dashboard/pre-interview',
  ],
  [ROLE_EVALUATOR]: [
    '/panel/decline-interview',
    '/panel/propose-new-time',
    '/panel/interviewer-dashboard',
    '/panel/accept-proposed-time',
    '/panel/calendar',
    '/panel/decline-proposed-time',
    '/panel/join-interview',
    '/panel/join-drive',
    '/job-description',
    '/evaluations',
    '/evaluations/:id',
    '/notification-inbox',
    '/organiser/reschedule',
    '/organiser/change-interviewer',
    '/dashboard/pre-interview',
  ],
  [ROLE_HIRING_MANAGER]: [
    '/panel/decline-interview',
    '/panel/propose-new-time',
    '/panel/interviewer-dashboard',
    '/panel/accept-proposed-time',
    '/panel/calendar',
    '/panel/decline-proposed-time',
    '/panel/join-interview',
    '/panel/join-drive',
    '/job-description',
    '/notification-inbox',
    '/organiser/reschedule',
    '/organiser/change-interviewer',
  ],
  // [ROLE_ADMIN]: ['/question-bank', '/taxonomy', '/library', '/admin'],
  // [ROLE_CONTENT_MANAGER]: ['/question-bank', '/taxonomy', '/library'],
};
