/*
 *
 * AppNotifications constants
 *
 */

export const DEFAULT_ACTION = 'app/AppNotifications/DEFAULT_ACTION';

export const LISTEN_WORKFLOW_ACTIVITIES = 'app/AppNotifications/LISTEN_WORKFLOW_ACTIVITIES';
export const STORE_WORKFLOW_ACTIVITIES = 'app/AppNotifications/STORE_WORKFLOW_ACTIVITIES';

export const MARK_READ = 'app/AppNotifications/MARK_READ';
export const MARK_ALL_READ = 'app/AppNotifications/MARK_ALL_READ';
export const MARK_UNREAD = 'app/AppNotifications/MARK_UNREAD';

export const MARK_PIN = 'app/AppNotifications/MARK_PIN';
export const MARK_UNPIN = 'app/AppNotifications/MARK_UNPIN';

export const MARK_DELETE = 'app/AppNotifications/MARK_DELETE';

export const ACTION_INITIATED = 'app/AppNotifications/ACTION_INITIATED';
export const UPDATE_INITIATED_ACTION = 'app/AppNotifications/UPDATE_INITIATED_ACTION';
