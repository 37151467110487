import EnglishUK from 'images/Languages/EnglishUK.png';
// import EnglishUS from 'images/Languages/EnglishUS.png';
import Spanish from 'images/Languages/Spanish.png';
// import Portugal from 'images/Languages/Portugal.png';
// import Welsh from 'images/Languages/Welsh.png';
// import Arabic from 'images/Languages/Arabic.png';
import French from 'images/Languages/French.png';
// import Italy from 'images/Languages/Italy.png';
// import German from 'images/Languages/German.png';

export const languages = [
  // {
  //   value: 'English (UK)',
  //   label: 'English (UK)',
  //   flag: EnglishUK,
  // },
  {
    value: 'en',
    label: 'English',
    flag: EnglishUK,
  },
  // {
  //   value: 'English (US)',
  //   label: 'English (US)',
  //   flag: EnglishUS,
  // },
  {
    value: 'es',
    label: 'Spanish',
    flag: Spanish,
  },
  // {
  //   value: 'Portuguese',
  //   label: 'Portuguese',
  //   flag: Portugal,
  // },
  // {
  //   value: 'Welsh',
  //   label: 'Welsh',
  //   flag: Welsh,
  // },
  // {
  //   value: 'Arabic',
  //   label: 'Arabic',
  //   flag: Arabic,
  // },
  {
    value: 'fr',
    label: 'French',
    flag: French,
  },
  // {
  //   value: 'Italian',
  //   label: 'Italian',
  //   flag: Italy,
  // },
  // {
  //   value: 'German',
  //   label: 'German',
  //   flag: German,
  // },
];
