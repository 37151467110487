/*
 *
 * NewWorkflowDetails actions
 *
 */

import {
  INITIALIZE,
  RE_FETCH_ALL_WORKFLOW_DATA,
  STORE_LATEST_WORKFLOW_CANDIDATES_DATA,
  SUBSCRIBE,
  UNSUBSCRIBE,
  UPDATE_SELECTED_TAB,
} from './constants';

export function initialize({ workflow_id, forceRefresh }) {
  return {
    type: INITIALIZE,
    workflow_id,
    forceRefresh: forceRefresh ?? false,
  };
}

export function updateSelectedTab({ tab }) {
  return {
    type: UPDATE_SELECTED_TAB,
    currentTab: tab,
  };
}

export function startSubscription(prop) {
  return {
    type: SUBSCRIBE,
    step: prop?.step ?? {},
  };
}

export function stopSubscription() {
  return {
    type: UNSUBSCRIBE,
  };
}

export function reFetchAllWorkflowData({ workflow_id, callback, forceRefresh = true }) {
  return {
    type: RE_FETCH_ALL_WORKFLOW_DATA,
    workflow_id,
    callback,
    forceRefresh,
  };
}

export function storeLatestWorkflowData({ data }) {
  return {
    type: STORE_LATEST_WORKFLOW_CANDIDATES_DATA,
    data: data ?? [],
  };
}
