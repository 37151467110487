import React, { useContext, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';

import { Forbidden, Error } from 'components/pages';
import AuthContext from 'contexts/AuthContext';
import canLogin from './canLogin';

function AuthenticatedRoute({ error, ...props }) {
  const { path } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const { user, isAuthenticated, isAuthenticating } = useContext(AuthContext);
  const authorized = useMemo(() => canLogin({ roles: user?.roles, path }), [path, user?.roles]);

  useEffect(() => {
    if (!isAuthenticating && !isAuthenticated) {
      navigate('/login', { state: { from: location?.pathname, search: location?.search } });
    }
  }, [isAuthenticated, isAuthenticating]);

  return (
    <>
      {user && !user.isAnonymous && error ? (
        <Error error={error} />
      ) : (
        <>{authorized ? props.children : <Forbidden />}</>
      )}
    </>
  );
}

AuthenticatedRoute.propTypes = {
  path: PropTypes.string,
  location: PropTypes.object,
};

export default AuthenticatedRoute;
