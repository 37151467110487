/**
 *
 * SidePanel
 *
 */

import React, { memo, Fragment, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export function SidePanel(props) {
  const {
    backgroundOverlay,
    outsideCloseButton,
    children,
    title,
    show,
    onClose,
    customHeader,
    disabledAutoClose,
    initialFocus,
    containerPadding,
  } = props;
  const [open, setOpen] = useState(show);
  useEffect(() => {
    setOpen(show);
  }, [show]);
  const closeFunc = () => {
    if (disabledAutoClose) {
      return;
    }
    if (onClose) {
      onClose();
    } else {
      setOpen(false);
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-50" onClose={closeFunc} initialFocus={initialFocus}>
        <div className="absolute inset-0 ">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay
              className={classNames(
                'absolute inset-0',
                backgroundOverlay ? 'bg-gray-500 bg-opacity-75 transition-opacity' : '',
              )}
            />
          </Transition.Child>

          <div data-testid="action-panel" className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="pointer-events-auto">
                {outsideCloseButton && (
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 left-0 -ml-10 flex pt-4 pr-2 sm:-ml-8 md:-ml-2 sm:pr-4">
                      <button
                        type="button"
                        className="rounded-md text-gray-300 hover:text-blue-500
                            focus:outline-none focus:ring-2 focus:ring-white"
                        onClick={closeFunc}
                      >
                        <span className="sr-only">Close panel</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                )}

                <div className={classNames('flex h-screen', 'flex-col divide-y', 'divide-gray-200 bg-white shadow-xl')}>
                  <div className="flex min-h-0 flex-1 flex-col ">
                    {!customHeader && (
                      <div
                        className={classNames(
                          'flex items-start justify-between top-0 pt-3 sticky',
                          'w-full 3 pr-10 mb-3 shadow-xs border-b-2 border-gray-100',
                        )}
                      >
                        <div className="ml-3 flex h-7 items-center align-middle justify-between mb-2  w-full">
                          {title && (
                            <Dialog.Title className="lg:text-lg text-base lg:font-medium font-normal text-gray-900 ">
                              {title}
                            </Dialog.Title>
                          )}
                          {!outsideCloseButton && (
                            <button
                              data-testid="button-close"
                              type="button"
                              className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                              onClick={closeFunc}
                            >
                              <span className="sr-only">Close panel</span>
                              <XIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          )}
                        </div>
                      </div>
                    )}
                    <div className={classNames('flex flex-1 flex-col overflow-y-auto', containerPadding)}>
                      {children}
                    </div>
                  </div>
                  {/* {isFunction(onSave) && (
                    <div className="flex flex-shrink-0 justify-end px-4 py-4 space-x-6">
                      <Button type="danger" size="md" LeadingIcon={BanIcon} onClick={closeFunc}>
                        <FormattedMessage {...messages.cancel} />
                      </Button>
                      <Button
                        type="primary"
                        size="md"
                        LeadingIcon={CheckCircleIcon}
                        onClick={() => {
                          onSave();
                          closeFunc();
                        }}
                      >
                        <FormattedMessage {...messages.save} />
                      </Button>
                    </div>
                  )} */}
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

SidePanel.propTypes = {
  backgroundOverlay: PropTypes.bool,
  outsideCloseButton: PropTypes.bool,
  // onSave: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  onClose: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  show: PropTypes.bool,
  customHeader: PropTypes.bool,
  disabledAutoClose: PropTypes.bool,
  containerPadding: PropTypes.string,
};

SidePanel.defaultProps = {
  backgroundOverlay: false,
  outsideCloseButton: false,
  show: false,
  // onSave: false,
  customHeader: false,
  disabledAutoClose: false,
  containerPadding: 'p-6',
};

export default memo(SidePanel);
