import { defineMessages } from 'react-intl';

export const scope = 'app.components.base.ConfirmDialog';

export default defineMessages({
  'Are you sure you want to cancel this transaction?': {
    id: `${scope}.transactionCancel_popup`,
    defaultMessage: 'Are you sure you want to cancel this transaction?',
  },
  'Are you sure?': {
    id: `${scope}.transactionCancel_popup_title`,
    defaultMessage: 'Are you sure?',
  },
  No: {
    id: `${scope}.no`,
    defaultMessage: 'No',
  },
  Yes: {
    id: `${scope}.yes`,
    defaultMessage: 'Yes',
  },
});
