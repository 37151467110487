/**
 *
 * ProfilePopover
 *
 */

import { Popover, Transition } from '@headlessui/react';
import classNames from 'classnames';
import AuthContext from 'contexts/AuthContext';
import React, { useContext, useMemo, memo, Fragment } from 'react';
import getDisplayName from 'utils/getDisplayName';
import { formatRoles } from 'utils/helpers';
import { get } from 'lodash';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import { NavigationIcon } from '../NavigationIcon';
import ProfilePopoverContent from './ProfilePopoverContent';

export function ProfilePopover(props) {
  const { expanded, showAccountSettings, accountSettings } = props;

  const { user } = useContext(AuthContext);
  const userName = useMemo(() => getDisplayName(user), [user]);
  const role = useMemo(() => user?.currentRole, [user]);

  return (
    <Popover className="relative">
      <Popover.Button className="outline-none">
        <NavigationIcon
          label={userName}
          isChevronRight
          expanded={expanded}
          additionalContent={
            get(messages, formatRoles(role))?.id ? (
              <FormattedMessage {...get(messages, formatRoles(role))} />
            ) : (
              formatRoles(role)
            )
          }
          customTooltipContent="Profile"
          user={user}
        />
      </Popover.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-100"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Popover.Panel className={classNames('absolute bg-white bottom-5', expanded ? 'left-44' : 'left-16')}>
          <ProfilePopoverContent
            name={userName}
            user_role={user?.currentRole}
            showAccountSettings={showAccountSettings}
            accountSettings={accountSettings}
          />
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}

ProfilePopover.propTypes = {};

export default memo(ProfilePopover);
