import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import loader from 'images/gif/Loader.gif';
import messages from './messages';

export function Loader({ showLate }) {
  return (
    <div className="flex justify-center items-center flex-1 space-y-6 ">
      {!showLate ? (
        <>
          <div className="w-[266px] flex flex-col justify-center items-center">
            <div className="w-40" data-testid="animation">
              <img src={loader} alt="loader" data-chromatic="ignore" />
            </div>
            <div className="w-fit mt-6 text-base font-normal text-gray-700">
              <FormattedMessage {...messages.initialTime} />
            </div>
          </div>
        </>
      ) : (
        <div className="w-[284px] flex flex-col justify-center items-center " alt="slow">
          <div className="w-40" data-testid="animation">
            <img src={loader} alt="loader" data-chromatic="ignore" />
          </div>
          <div className="w-fit mt-6 text-base font-normal text-gray-700">
            <FormattedMessage {...messages.slowTime} />
          </div>
          <div className="w-fit text-base font-normal text-gray-700">
            <FormattedMessage {...messages.contact} />{' '}
            <a
              href="https://talview.freshdesk.com/support/tickets/new"
              className="text-blue-500 font-medium"
              title="Support"
            >
              <FormattedMessage {...messages.support} />
            </a>{' '}
            <FormattedMessage {...messages.assistanceMsg} />
          </div>
        </div>
      )}
    </div>
  );
}

Loader.propTypes = {
  timeout: PropTypes.number,
};

Loader.defaultProps = {
  timeout: 10000,
};

export default memo(Loader);
