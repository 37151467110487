import { call, put, fork, all, takeLatest, select, takeEvery } from 'redux-saga/effects';
import fetchData from 'utils/fetchData';
import { getByKey, getConflict, catchError, parseDateTime, getCurrentDate } from 'utils/helpers';
import { map, flatMap, uniqBy, groupBy, filter, find, forEach, get, omitBy, isNil, flatten } from 'lodash';
import { email } from 'utils/validate';
import {
  GET_AUTH_USERS_AVAILABILITIES,
  GET_AUTH_USERS_BUSY,
  GET_META_DATA,
  GET_ORG_AUTH_USERS,
} from 'containers/App/queries';
import { ROLE_EVALUATOR } from 'utils/data/roles';
import { uploadFileAction } from 'utils/mixpanelActions';
import { meetingStatus } from 'utils/data/meetingStatus';
import { storeAppMetaData } from 'containers/App/actions';
import { constructSlot, getInitialSlots, getStartDate, parseExcelEvaluators, parseSelectedData } from './helpers';
import {
  ADD_BULK_SLOTS,
  ADD_SELECTED_CANDIDATE,
  ADD_SELECTED_EVALUATOR,
  FETCH_EVALUATORS,
  INITIALIZATION_COMPLETED,
  INITIALIZE,
  PARSE_FILE_UPLOAD_DATA,
  REMOVE_SLOT,
  STORE_CALENDAR_SCHEDULE,
  STORE_FETCHED_EVALUATORS,
  STORE_FETCHED_TIMEZONES,
  STORE_FILE_UPLOAD_DATA,
  STORE_UPDATED_SLOT,
  UPDATE_BULK_SLOTS,
  FETCH_CALENDAR_SLOT,
  UPDATE_SELECTED_DURATION,
  UPDATE_SLOT,
  FETCH_SLOT,
} from './constants';
import { GLOBAL_ERROR } from '../App/constants';
import { selectAllEvaluators, selectDuration, selectSelectedEvaluators, selectSlots } from './selectors';
export function* updateDurationSaga({ payload }) {
  try {
    const slots = yield select(selectSlots);
    const updateSlots = {};
    // eslint-disable-next-line no-restricted-syntax
    for (const key in slots) {
      if (Object.hasOwnProperty.call(slots, key)) {
        updateSlots[key] = yield call(constructSlot, { ...slots[key], duration: payload });
      }
    }
    yield put({ type: ADD_BULK_SLOTS, payload: updateSlots });
  } catch (error) {
    catchError(error);
  }
}

export function* updateSlotSaga({ slotId, payload }) {
  try {
    const duration = yield select(selectDuration);
    const slot = yield call(constructSlot, { ...payload, duration });
    const formattedSlot = { [slotId]: slot };
    yield put({ type: STORE_UPDATED_SLOT, payload: formattedSlot });
  } catch (error) {
    catchError(error);
  }
}

export function* fetchEvaluatorsSaga() {
  try {
    const metaResponse = yield call(fetchData, {
      queryString: GET_META_DATA,
    });
    const { auth_user } = yield call(fetchData, {
      queryString: GET_ORG_AUTH_USERS,
    });
    const { md_timezone } = metaResponse;
    yield put(storeAppMetaData({ response: metaResponse }));
    yield put({ type: STORE_FETCHED_TIMEZONES, payload: md_timezone });
    const response = yield call(
      filter,
      auth_user,
      authUser => find(authUser.roles, ['role', ROLE_EVALUATOR]) && authUser.email,
    );
    yield put({
      type: STORE_FETCHED_EVALUATORS,
      payload: response && Array.isArray(response) && response.length ? getByKey(groupBy(response, 'email')) : {},
    });
  } catch (error) {
    yield call(catchError, error);
    if (get(error, 'graphQLErrors.0.extensions.code') === 'invalid-jwt') {
      yield put({ type: GLOBAL_ERROR, error });
    }
  }
}

export function* parseEvaluatorConflictsSaga() {
  try {
    const evaluatorSlots = yield select(selectSelectedEvaluators);
    const slots = yield select(selectSlots);
    const updatedSlots = {};
    yield call(forEach, Object.keys(slots), key => {
      const slot = slots[key];
      updatedSlots[key] = {
        ...slot,
        // emptyDate: slotOptionsWithTimeValidation(slot),
        evaluators: map(omitBy(slot?.evaluators, isNil), e => ({
          ...e,
          hasConflict: false,
        })),
      };
    });
    // eslint-disable-next-line no-restricted-syntax
    for (const attendee in evaluatorSlots) {
      if (Object.hasOwnProperty.call(evaluatorSlots, attendee)) {
        const attendeeSlots = evaluatorSlots[attendee];
        if (attendeeSlots && Array.isArray(attendeeSlots) && attendeeSlots.length > 1) {
          yield call(forEach, attendeeSlots, attendeeSlot => {
            const conflictSlots = filter(attendeeSlots, slot => getConflict(slot, attendeeSlot));
            if (conflictSlots && Array.isArray(conflictSlots) && conflictSlots.length) {
              const slot = slots[attendeeSlot?.id];
              const updatedSlot = updatedSlots[slot?.id];
              updatedSlots[slot?.id] = {
                ...updatedSlot,
                evaluators: map(updatedSlot?.evaluators, e => ({
                  ...e,
                  hasConflict: e?.email === attendee ? true : e.hasConflict,
                })),
              };
            }
          });
        }
      }
    }
    if (updatedSlots && Object.keys(updatedSlots).length) {
      yield put({ type: UPDATE_BULK_SLOTS, payload: updatedSlots });
    }
  } catch (error) {
    catchError(error);
  }
}

export function* parseEvaluatorSaga() {
  try {
    const allSlots = yield select(selectSlots);
    const allEvaluators = yield call(
      flatMap,
      map(Object.values(allSlots), s => s.evaluators),
    );
    const uniqueEvaluators = yield call(uniqBy, allEvaluators, 'email');
    const evaluatorsWithSlot = yield call(map, uniqueEvaluators, evaluator => {
      const slots = filter(Object.values(allSlots), s => find(s.evaluators, e => e?.email === evaluator?.email));
      return map(slots, slot => ({
        id: slot?.id,
        evaluator: evaluator?.email,
        candidate: slot?.candidate?.email,
        startDate: slot?.startDate,
        endDate: slot?.endDate,
      }));
    });
    const evaluatorsGroup = yield call(groupBy, flatMap(evaluatorsWithSlot), 'evaluator');
    yield put({ type: ADD_SELECTED_EVALUATOR, payload: evaluatorsGroup || {} });
  } catch (error) {
    catchError(error);
  }
}

export function* parseCandidateSaga() {
  try {
    const allSlots = yield select(selectSlots);
    const candidatesWithSlot = yield call(map, allSlots, slot => ({
      id: slot?.id,
      candidate: slot?.candidate?.email,
      evaluators:
        slot?.evaluators && Array.isArray(slot?.evaluators) && slot?.evaluators?.length
          ? map(slot?.evaluators, evaluator => evaluator?.email)
          : [],
      startDate: slot.startDate,
      endDate: slot?.endDate,
    }));
    const candidatesSlotsGroup = yield call(groupBy, candidatesWithSlot, 'candidate');
    yield put({ type: ADD_SELECTED_CANDIDATE, payload: candidatesSlotsGroup });
  } catch (error) {
    catchError(error);
  }
}

export function* parseFileUploadDataSaga({ data }) {
  try {
    const duration = yield select(selectDuration);
    const allEvaluators = yield select(selectAllEvaluators);
    const processedData = yield call(map, data, record => {
      if (!record?.candidate || !email(record?.candidate)) {
        return null;
      }
      const evaluators = parseExcelEvaluators(record, allEvaluators);
      const startDate = getStartDate(record?.startDate, record?.start_time);
      return constructSlot({
        candidate: {
          email: record?.candidate,
        },
        evaluators,
        startDate,
        duration,
      });
    });
    uploadFileAction({ payload: processedData });
    const filteredData = filter(processedData, d => d);
    const normalizedSlots = yield call(groupBy, filteredData, 'id');
    yield put({ type: STORE_FILE_UPLOAD_DATA, payload: getByKey(normalizedSlots) });
  } catch (error) {
    catchError(error);
  }
}

export function* initializeSaga({ selectedData }) {
  try {
    yield all([put({ type: FETCH_EVALUATORS })]);
    const selectedDuration = yield select(selectDuration);
    const data = yield call(parseSelectedData, selectedData);

    const slots = yield call(getInitialSlots, { data, selectedDuration });
    const normalizedSlots = yield call(groupBy, slots, 'id');
    yield put({ type: ADD_BULK_SLOTS, payload: getByKey(normalizedSlots) });

    yield put({ type: INITIALIZATION_COMPLETED });
  } catch (error) {
    catchError(error);
    if (get(error, 'graphQLErrors.0.extensions.code') === 'invalid-jwt') {
      yield put({ type: GLOBAL_ERROR, error });
    }
  }
}

export function* fetchAndMapCalendarAvailabilitiesSaga({ data, callback }) {
  try {
    if (data.evaluators && data.evaluators.length) {
      const selectedDate = parseDateTime(data?.startDate) ? parseDateTime(data?.startDate) : getCurrentDate();
      const freeSlots = yield fetchData({
        queryString: GET_AUTH_USERS_AVAILABILITIES,
        queryVariables: {
          payload: [
            {
              user_ids: data.evaluators.map(eva => eva.id),
              duration: '00:30:00',
              from: selectedDate.startOf('day').toUTC().toISO(),
              to: selectedDate.endOf('day').toUTC().toISO(),
            },
          ],
        },
        forceRefresh: true,
      });

      const payload = data.evaluators.map(item => ({
        user_id: item?.id,
        from: selectedDate.startOf('day').toUTC().toISO(),
        to: selectedDate.endOf('day').toUTC().toISO(),
        status: [
          meetingStatus?.COMPLETED,
          meetingStatus?.DRAFT,
          meetingStatus?.SCHEDULED,
          meetingStatus?.IN_PROGRESS,
          meetingStatus?.NO_SHOW,
        ],
      }));

      const busySlots = yield fetchData({
        queryString: GET_AUTH_USERS_BUSY,
        queryVariables: {
          payload,
        },
        forceRefresh: true,
      });

      callback.onSuccess();
      const freeSlotResponse = get(freeSlots?.sch_get_meeting_slots, '0');

      yield put({
        type: STORE_CALENDAR_SCHEDULE,
        payload: {
          [data.id]: {
            busy: flatten(map(busySlots?.wft_free_busy, res => res?.data)),
            free: freeSlotResponse?.data?.slots,
          },
        },
      });
    }
  } catch (error) {
    yield call(catchError, error, true);
    if (callback && callback.onError) {
      yield call(callback.onError, error);
    }
    if (get(error, 'graphQLErrors.0.extensions.code') === 'invalid-jwt') {
      yield put({ type: GLOBAL_ERROR, error });
    }
  }
}

export function* fetchSlotsSaga({ data, callback, duration }) {
  try {
    if (data) {
      const freeSlots = yield call(fetchData, {
        queryString: GET_AUTH_USERS_AVAILABILITIES,
        queryVariables: {
          payload: [{ user_ids: data?.id, duration }],
        },
        forceRefresh: true,
      });
      const freeSlotResponse = get(freeSlots?.sch_get_meeting_slots, '0');
      yield call(callback.onSuccess, freeSlotResponse?.data?.slots);
    }
  } catch (error) {
    yield call(catchError, error, true);
    if (callback && callback.onError) {
      yield call(callback.onError, error);
    }
    if (get(error, 'graphQLErrors.0.extensions.code') === 'invalid-jwt') {
      yield put({ type: GLOBAL_ERROR, error });
    }
  }
}

export function* fetchEvaluatorsWatcher() {
  yield takeLatest(FETCH_EVALUATORS, fetchEvaluatorsSaga);
}

export function* updateDurationWatcher() {
  yield takeLatest(UPDATE_SELECTED_DURATION, updateDurationSaga);
}

export function* parseEvaluatorWatcher() {
  yield takeLatest([ADD_BULK_SLOTS, STORE_UPDATED_SLOT, REMOVE_SLOT, STORE_FILE_UPLOAD_DATA], parseEvaluatorSaga);
}

export function* parseCandidateWatch() {
  yield takeLatest([ADD_BULK_SLOTS, STORE_UPDATED_SLOT, REMOVE_SLOT, STORE_FILE_UPLOAD_DATA], parseCandidateSaga);
}

export function* updateSlotWatcher() {
  yield takeLatest(UPDATE_SLOT, updateSlotSaga);
}

export function* parseEvaluatorConflictsWatcher() {
  yield takeLatest([ADD_SELECTED_EVALUATOR], parseEvaluatorConflictsSaga);
}

export function* parseFileUploadDataWatcher() {
  yield takeLatest(PARSE_FILE_UPLOAD_DATA, parseFileUploadDataSaga);
}

export function* initializeWatcher() {
  yield takeLatest(INITIALIZE, initializeSaga);
}

export function* fetchAndMapCalendarAvailabilitiesWatcher() {
  yield takeEvery(FETCH_CALENDAR_SLOT, fetchAndMapCalendarAvailabilitiesSaga);
}

export function* fetchSlotsWatcher() {
  yield takeEvery(FETCH_SLOT, fetchSlotsSaga);
}

export default function* bulkScheduleSaga() {
  yield all([
    fork(fetchEvaluatorsWatcher),
    fork(initializeWatcher),
    fork(updateDurationWatcher),
    fork(parseEvaluatorWatcher),
    fork(updateSlotWatcher),
    fork(parseCandidateWatch),
    fork(parseEvaluatorConflictsWatcher),
    fork(parseFileUploadDataWatcher),
    fork(fetchAndMapCalendarAvailabilitiesWatcher),
    fork(fetchSlotsWatcher),
  ]);
}
