import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { faCircleXmark } from '@fortawesome/pro-solid-svg-icons';
import { map } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { catchError, handleEnterKeyPress } from 'utils/helpers';
import { fileTypes, handleImageUpload } from 'utils/imageUploadHelpers';
import classNames from 'classnames';
import { captureScreen } from 'utils/screenCapture';
import messages from './messages';

const ImageUpload = props => {
  const { supportedFileTypes = fileTypes, setFiles, multiple = false, isLoading } = props;
  const [files, addFiles] = useState([]);
  const fileInputRef = useRef();

  const restoreModal = () => {
    const modal = document.getElementById('headlessui-portal-root');
    if (modal) {
      modal.style.visibility = 'visible';
    }
  };

  const removeFile = index => {
    addFiles(prev => {
      const cloneState = [...prev];
      cloneState.splice(index, 1);
      return cloneState;
    });
  };

  const triggerFileUpload = () => {
    if (fileInputRef.current && fileInputRef.current?.click && !isLoading) {
      fileInputRef.current.value = null;
      fileInputRef.current.click();
    }
  };

  const storeSelectedFile = file => {
    addFiles(prev => prev.concat(file));
    restoreModal();
  };

  const fileUploadError = error => {
    restoreModal();
    catchError(error);
  };

  useEffect(() => {
    if (setFiles) {
      setFiles(() => files);
    }
  }, [files]);

  const triggerScreenCapture = () => {
    const modal = document.getElementById('headlessui-portal-root');
    if (modal) {
      modal.style.visibility = 'hidden';
    }
    captureScreen({ callback: { onSuccess: storeSelectedFile, onError: fileUploadError } });
  };

  return (
    <>
      {files.length ? (
        <div
          className="flex flex-1 items-center text-sm space-x-3 mx-3"
          style={{ width: `${56 + 72 * files.length}px` }}
        >
          {map(files, (file, i) => (
            <div key={i} className="relative">
              <div>
                <img src={file} alt={`file-${i}`} className="h-14 w-14 rounded" />
              </div>
              <div
                className={classNames(
                  'absolute bg-white right-[-6px] rounded-full top-[-6px] h-[18px] w-[18px]',
                  isLoading ? 'cursor-default' : 'cursor-pointer',
                )}
                onClick={() => !isLoading && removeFile(i)}
                onKeyDown={e => handleEnterKeyPress(e, () => removeFile(i))}
                role="button"
                tabIndex="-1"
                data-testid={`remove-${i}`}
              >
                <FontAwesomeIcon icon={faCircleXmark} className="text-gray-700 text-[18px]" />
              </div>
            </div>
          ))}
          <div
            className={classNames(
              'bg-blue-100 h-14 w-14 flex justify-center items-center rounded',
              isLoading ? 'cursor-default' : 'cursor-pointer',
            )}
            onClick={triggerFileUpload}
            onKeyDown={e => handleEnterKeyPress(e, triggerFileUpload)}
            role="button"
            tabIndex="-1"
            data-testid="button-file-upload"
          >
            <FontAwesomeIcon icon={faPlus} className="text-blue-900 w-3 h-3" />
          </div>
        </div>
      ) : (
        <div className="flex justify-center items-center h-full w-full text-sm">
          <div
            className="text-blue-700 underline cursor-pointer"
            onClick={triggerFileUpload}
            onKeyDown={e => handleEnterKeyPress(e, triggerFileUpload)}
            role="button"
            tabIndex="-1"
          >
            <FormattedMessage {...messages.upload_picture} />
          </div>
          <div className="text-gray-400 mx-2">
            <FormattedMessage {...messages.or} />
          </div>
          <div
            className="text-blue-700 underline cursor-pointer"
            onClick={triggerScreenCapture}
            onKeyDown={e => handleEnterKeyPress(e, triggerScreenCapture)}
            role="button"
            tabIndex="-1"
          >
            <FormattedMessage {...messages.capture_screenshot} />
          </div>
        </div>
      )}
      <input
        ref={fileInputRef}
        type="file"
        accept={supportedFileTypes.join(',')}
        className="hidden"
        data-testid="input-file"
        multiple={multiple}
        onChange={event =>
          handleImageUpload({
            event,
            callback: { onSuccess: storeSelectedFile, onError: fileUploadError },
            supportedFileTypes,
          })
        }
      />
    </>
  );
};

export default ImageUpload;
