import classNames from 'classnames';
import Button from 'components/base/Button/newButton';
import ActionContext from 'contexts/ActionContext';
import { isNil, omitBy, trim } from 'lodash';
import React, { useContext, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { getCurrentDate } from 'utils/helpers';
import SuccessView from './SuccessView';
import ImageUpload from './ImageUpload';
import messages from './messages';

const Content = props => {
  const { handleClose, toggleLoading, isLoading, successView, toggleSuccessView } = props;
  const [description, setDescription] = useState('');
  const [files, setFiles] = useState('');
  const { onSubmit, action, showNotification } = useContext(ActionContext);

  const onSuccess = () => {
    toggleLoading(false);
    toggleSuccessView(true);
  };

  const onError = error => {
    toggleLoading(false);
    showNotification({
      variant: 'danger',
      title: <FormattedMessage {...messages.error_message_title} />,
      summary: (
        <>
          <FormattedMessage {...messages.error_message_summary} /> <span>{error?.message}</span>
        </>
      ),
    });
  };

  const disableAction = useMemo(() => !trim(description).length, [description]);

  const handleSubmit = () => {
    toggleLoading(true);
    const payload = omitBy(
      {
        description: trim(description),
        img: files.length ? files[0] : null,
        file_name: getCurrentDate().toUTC().toISO(),
      },
      isNil,
    );
    onSubmit({
      payload,
      action: action?.reportIssue,
      callback: {
        onSuccess,
        onError,
      },
    });
  };

  if (successView) {
    return <SuccessView handleClose={handleClose} toggleSuccessView={toggleSuccessView} />;
  }

  return (
    <div className="w-96 text-sm text-gray-900 pt-6">
      <div className="space-y-3">
        <div>
          <FormattedMessage {...messages.summary} />*
        </div>
        <div>
          <textarea
            className="w-full border-gray-300 border-[1px] rounded text-sm placeholder:text-gray-400"
            rows="6"
            placeholder="Jot down your queries..."
            value={description}
            data-testid="description-box"
            onChange={e => setDescription(e.target.value)}
            disabled={isLoading}
          />
        </div>
      </div>
      <div className="space-y-3 mt-2">
        <div>
          <FormattedMessage {...messages.add_screenshot} />
        </div>
        <div
          className={classNames(
            'flex flex-1 flex-col overflow-auto',
            'border-gray-300 border-dashed border-[1px] h-20 w-full rounded',
          )}
        >
          <ImageUpload setFiles={setFiles} multiple isLoading={isLoading} />
        </div>
      </div>
      <div className="flex justify-end items-center text-sm space-x-3 mt-7">
        <div className="w-[90px]">
          <Button type="tertiary" size="sm" isFullWidth onClick={handleClose} disabled={isLoading}>
            <FormattedMessage {...messages.cancel} />
          </Button>
        </div>
        <div className="w-28">
          <Button
            size="sm"
            isFullWidth
            onClick={handleSubmit}
            disabled={disableAction || isLoading}
            loading={isLoading}
          >
            <FormattedMessage {...messages.submit} />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Content;
