/* eslint-disable no-param-reassign */
import React from 'react';
import {
  ROLE_CONTENT_MANAGER,
  // ROLE_MASTER_RECRUITER,
  // ROLE_RECRUITER,
  ROLE_MASTER_CONTENT_MANAGER,
  ROLE_TENANT_ADMIN,
} from 'utils/data/roles';
import Auth from 'utils/auth';
import {
  faUserGear,
  faMemo,
  // faBuildingCircleArrowRight,
  // faUserMagnifyingGlass,
} from '@fortawesome/pro-light-svg-icons';
import { FormattedMessage } from 'react-intl';
import { filterItems } from '../NavModules/items';
import messages from './messages';

export const getItems = () => {
  const roles = Auth.roles || [];
  const items = [
    {
      icon: faUserGear,
      name: <FormattedMessage {...messages?.adminConsole} />,
      summary: <FormattedMessage {...messages?.adminConsoleSummary} />,
      route: process.env.REACT_APP_AUTH_URL, // Have to collect from growthbook
      roles: [ROLE_TENANT_ADMIN],
    },
    {
      icon: faMemo,
      name: <FormattedMessage {...messages?.assessments} />,
      summary: <FormattedMessage {...messages?.assessmentsSummary} />,
      route: process.env.ASSESSMENT_ENGINE_URL, // Have to collect from growthbook
      roles: [ROLE_TENANT_ADMIN, ROLE_CONTENT_MANAGER, ROLE_MASTER_CONTENT_MANAGER],
    },
    // {
    //   icon: faUserMagnifyingGlass,
    //   name: <FormattedMessage {...messages?.recruit} />,
    //   summary: <FormattedMessage {...messages?.recruitSummary} />,
    //   route: process.env.RECRUIT_URL, // Have to collect from growthbook
    //   roles: [ROLE_TENANT_ADMIN, ROLE_RECRUITER, ROLE_MASTER_RECRUITER],
    // },
    // {
    //   icon: faBuildingCircleArrowRight,
    //   name: <FormattedMessage {...messages?.campusLabel} />,
    //   summary: <FormattedMessage {...messages?.campusSummary} />,
    //   route: '/campus', // Have to collect from growthbook
    //   roles: [ROLE_TENANT_ADMIN, ROLE_RECRUITER, ROLE_MASTER_RECRUITER],
    // },
  ];
  return filterItems({ items, roles });
};
