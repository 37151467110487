/*
 *
 * WorkflowCandidateStepDetails constants
 *
 */

export const DEFAULT_ACTION = 'app/WorkflowCandidateStepDetails/DEFAULT_ACTION';

export const INITIALIZE = 'app/WorkflowCandidateStepDetails/INITIALIZE';
export const INITIALIZATION_COMPLETED = 'app/WorkflowCandidateStepDetails/INITIALIZE_COMPLETED';

export const FETCH_CANDIDATE_STEP_DATA = 'app/WorkflowCandidateStepDetails/FETCH_CANDIDATE_STEP_DATA';
export const STORE_CANDIDATE_STEP_DATA = 'app/WorkflowCandidateStepDetails/STORE_CANDIDATE_STEP_DATA';

export const CHANGE_CANDIDATE = 'app/WorkflowCandidateStepDetails/CHANGE_CANDIDATE';

export const CHANGE_WORKFLOW_STEP = 'app/WorkflowCandidateStepDetails/CHANGE_WORKFLOW_STEP';
export const UPDATE_STATE_ON_STEP_CHANGE = 'app/WorkflowCandidateStepDetails/UPDATE_STATE_ON_STEP_CHANGE';

export const PARSE_ALL_CANDIDATE_STEP = 'app/WorkflowCandidateStepDetails/PARSE_ALL_CANDIDATE_STEP';

export const STORE_WORKFLOW_CANDIDATE = 'app/WorkflowCandidateStepDetails/STORE_WORKFLOW_CANDIDATE';

export const STORE_FORMATTED_DATA = 'app/WorkflowCandidateStepDetails/STORE_FORMATTED_DATA';

export const REFETCH_WORKFLOW_CANDIDATE = 'app/WorkflowCandidateStepDetails/REFETCH_WORKFLOW_CANDIDATE';

export const FETCH_PREVIOUS_WORKFLOWS = 'app/WorkflowCandidateStepDetails/FETCH_PREVIOUS_WORKFLOWS';

export const STORE_PREVIOUS_WORKFLOWS = 'app/WorkflowCandidateStepDetails/STORE_PREVIOUS_WORKFLOWS';

export const REMOVE_PREVIOUS_WORKFLOWS = 'app/WorkflowCandidateStepDetails/REMOVE_PREVIOUS_WORKFLOWS';

export const GENERATE_VOICE_TOKEN = 'app/WorkflowCandidateStepDetails/GENERATE_VOICE_TOKEN';

export const STORE_VOICE_TOKEN = 'app/WorkflowCandidateStepDetails/STORE_VOICE_TOKEN';

export const FETCH_CANDIDATE_CALL_HISTORY = 'app/WorkflowCandidateStepDetails/FETCH_CANDIDATE_CALL_HISTORY';

export const STORE_CANDIDATE_CALL_HISTORY_DATA = 'app/WorkflowCandidateStepDetails/STORE_CANDIDATE_CALL_HISTORY_DATA';
