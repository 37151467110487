import { find } from 'lodash';
import { DateTime } from 'luxon';

export const recurrence = [
  { id: 1, label: 'Daily', value: '0 0 * * *' },
  { id: 2, label: 'Only Weekdays (Mon - Fri)', value: '0 0 * * 1-5' },
  { id: 3, label: 'Only Weekends (Sat, Sun)', value: '0 0 * * 6,0' },
  { id: 4, label: 'Alternate Days', value: '0 0 */2 * *' },
  // { id: 5, label: 'Does Not Repeat', value: null },
];

export const getRecurrenceDetails = expression => {
  if (expression) {
    if (expression === '0 23 */2 * *') {
      // This is done to support corn_express for the drives which alternate days is created with this expression
      return recurrence[3];
    }
    return find(recurrence, ['value', expression]);
  }
  return recurrence[0];
};

export const shouldDisableDriveActionsAsPerOccurrence = (selectedDate, drive) => {
  const date = new Date(selectedDate);
  const dateNum = date.getDay();
  const occurrence = getRecurrenceDetails(drive?.cron_expression);
  if (occurrence) {
    if (occurrence.id === 3 && (dateNum === 6 || dateNum === 0)) {
      return false;
    }
    if (occurrence.id === 2 && dateNum >= 1 && dateNum <= 5) {
      return false;
    }
    if (occurrence.id === 1) {
      return false;
    }
    if (occurrence.id === 4) {
      const currentDate = DateTime.fromISO(selectedDate);
      const driveStartDate = DateTime.fromISO(drive?.start_date);
      const driveEndDate = DateTime.fromISO(drive?.end_date);
      const difference = driveEndDate.diff(driveStartDate, ['days']);
      const actualDifference = Math.ceil(difference?.values?.days);
      const arr = [];
      for (let i = 0; i < actualDifference; i += 2) {
        arr.push(driveStartDate.day + i);
      }
      if (arr.includes(currentDate.day)) {
        return false;
      }
      return true;
    }
    return true;
  }
  return false;
};

export default recurrence;
