/*
 * Loading Messages
 *
 * This contains all the text for the Loading component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.base.New.Loader';

export default defineMessages({
  initialTime: {
    id: `${scope}.initialTime`,
    defaultMessage: 'Getting everything ready, hang tight!',
  },
  slowTime: {
    id: `${scope}.slowTime`,
    defaultMessage: 'This is taking longer than usual.',
  },
  contact: {
    id: `${scope}.contact`,
    defaultMessage: 'Contact',
  },
  support: {
    id: `${scope}.support`,
    defaultMessage: 'Support',
  },
  assistanceMsg: {
    id: `${scope}.assistanceMsg`,
    defaultMessage: 'if you need assistance.',
  },
});
