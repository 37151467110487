/**
 *
 * ConfirmDialog
 *
 */

import React, { memo, Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Dialog, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { get } from 'lodash';
import { Player as LottiePlayer } from '@lottiefiles/react-lottie-player';
import { faXmark } from '@fortawesome/pro-light-svg-icons';
import { FormattedMessage } from 'react-intl';
import Button from '../Button/newButton';
import messages from './messages';

export function ConfirmDialog(props) {
  const {
    open,
    title,
    message,
    handlePositiveClick,
    handleNegativeClick,
    positiveButtonLabel,
    negativeButtonLabel,
    customContent,
    isCustomContent,
    isLoading,
    handleClose,
    disabledAutoClose,
    icon,
    variant,
    disabled,
    initialFocus,
  } = props;

  const onNegativeClick = () => {
    if (handleNegativeClick) {
      handleNegativeClick();
    }
  };
  const onPositiveClick = () => {
    if (handlePositiveClick) handlePositiveClick();
  };

  const onClose = () => {
    if (!disabledAutoClose && handleClose) {
      handleClose();
    }
  };

  const variantMap = useMemo(
    () => ({
      alert: 'text-red-500 ',
      success: 'text-green-500',
      primary: 'text-accent-700',
    }),
    [],
  );

  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog
        data-testid="closeDialog"
        as="div"
        className="relative z-50"
        onClose={onClose}
        initialFocus={initialFocus}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-75" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={classNames(
                  'transform rounded-lg bg-white p-6',
                  'text-left align-middle shadow-xl transition-all',
                )}
              >
                <Dialog.Title
                  as="h3"
                  className="flex flex-1 justify-between items-center text-xl font-semibold text-gray-900"
                >
                  <span>{get(messages, title)?.id ? <FormattedMessage {...get(messages, title)} /> : title}</span>
                  <FontAwesomeIcon
                    icon={faXmark}
                    className={classNames('ml-2', disabled ? 'cursor-not-allowed' : 'cursor-pointer')}
                    onClick={() => !disabled && handleClose && handleClose()}
                    data-testid="confirm-modal-close"
                    tabIndex="-1"
                  />
                </Dialog.Title>
                {isCustomContent ? (
                  <>{React.cloneElement(customContent, { onNegativeClick, onPositiveClick, onClose })}</>
                ) : (
                  <div data-testid="icon" className="mt-6">
                    <div className="flex justify-start items-center w-[340px] text-sm text-gray-500">
                      {icon && variant === 'animation' ? (
                        <div className="w-40" data-chromatic="ignore">
                          <LottiePlayer autoplay loop src={icon}></LottiePlayer>
                        </div>
                      ) : (
                        <FontAwesomeIcon
                          icon={icon}
                          className={classNames(get(variantMap, variant || 'text-red-500'), 'text-5xl mr-4')}
                        />
                      )}

                      {get(messages, message)?.id ? <FormattedMessage {...get(messages, message)} /> : message}
                    </div>
                  </div>
                )}

                {positiveButtonLabel || negativeButtonLabel ? (
                  <div className="flex justify-end space-x-3 mt-6 ]">
                    {negativeButtonLabel ? (
                      <div className="w-[80px]">
                        <Button onClick={onNegativeClick} disabled={disabled} type="tertiary" isFullWidth>
                          {get(messages, negativeButtonLabel)?.id ? (
                            <FormattedMessage {...get(messages, negativeButtonLabel)} />
                          ) : (
                            negativeButtonLabel
                          )}
                        </Button>
                      </div>
                    ) : (
                      <></>
                    )}
                    {positiveButtonLabel ? (
                      <div className="w-[80px]">
                        <Button onClick={onPositiveClick} disabled={disabled} loading={isLoading} isFullWidth>
                          {get(messages, positiveButtonLabel)?.id ? (
                            <FormattedMessage {...get(messages, positiveButtonLabel)} />
                          ) : (
                            positiveButtonLabel
                          )}
                        </Button>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                ) : (
                  <></>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

ConfirmDialog.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  variant: PropTypes.oneOf(['alert', 'success', 'primary', 'animation']),
  message: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  handlePositiveClick: PropTypes.func,
  handleNegativeClick: PropTypes.func,
  positiveButtonLabel: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  negativeButtonLabel: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  customContent: PropTypes.any,
  isCustomContent: PropTypes.bool,
  handleClose: PropTypes.func,
  disabled: PropTypes.bool,
};

ConfirmDialog.defaultProps = {
  title: 'Are you sure?',
  open: false,
  variant: 'alert',
  message: 'Are you sure you want to cancel this transaction?',
  isCustomContent: false,
};
export default memo(ConfirmDialog);
