/**
 *
 * NavModules
 *
 */

import React, { memo } from 'react';
// import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useLocation, useNavigate } from 'react-router';
import { getItems } from 'components/base/SideNavigation/NavModules/items';
import { map } from 'lodash';
import { NavigationIcon } from '../NavigationIcon';

export function NavModules(props) {
  const { expanded } = props;
  const location = useLocation();
  const navigate = useNavigate();

  const filteredRoutes = getItems();

  const routes = map(filteredRoutes, route => ({
    ...route,
    label: <FormattedMessage {...route.label} />,
  }));

  const handleClick = item => {
    if (item?.path) {
      navigate(item?.path, {
        state: {
          from: location.pathname,
        },
      });
    }
  };

  return (
    <div className="flex flex-col gap-y-4 pt-8">
      {routes?.map(module => (
        <NavigationIcon
          item={module}
          icon={module.icon}
          key={module.name}
          label={module.label}
          expanded={expanded}
          selected={location.pathname.includes(module?.path)}
          onClick={handleClick}
          name={module.name}
        />
      ))}
    </div>
  );
}

NavModules.propTypes = {};

export default memo(NavModules);
