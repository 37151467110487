import { trim } from 'lodash';
import { setPersistence, signInWithCustomToken, browserLocalPersistence } from 'firebase/auth';
import { auth } from 'utils/firebase';
import Auth from 'utils/auth';

export async function getUser() {
  return auth.currentUser;
}

export async function authenticateToken({ access_token, errorCallback }) {
  if (!trim(access_token)) throw new Error('Invalid Access Token');
  await setPersistence(auth, browserLocalPersistence);
  const UC = await signInWithCustomToken(auth, access_token);
  if (UC.user) {
    const token = await UC.user.getIdToken();
    return Auth.createClient({
      graphToken: token,
      errorCallback,
    });
  }
  return false;
}
