import { capitalize, find, lowerCase } from 'lodash';

export const driveStatus = {
  ONGOING: 'ON_GOING',
  SCHEDULED: 'SCHEDULED',
  DRAFT: 'DRAFT',
  COMPLETED: 'COMPLETED',
  CANCELLED: 'CANCELLED',
  INACTIVE: 'INACTIVE',
};

export const driveStatusTheme = {
  ONGOING: { bg: 'bg-blue-100', text: 'text-blue-900' },
  SCHEDULED: { bg: 'bg-yellow-100', text: 'text-yellow-900' },
  DRAFT: { bg: 'bg-grey-100', text: 'text-grey-900' },
  COMPLETED: { bg: 'bg-green-100', text: 'text-green-900' },
  CANCELLED: { bg: 'bg-red-100', text: 'text-red-900' },
  INACTIVE: { bg: 'bg-grey-100', text: 'text-grey-900' },
};

export const driveStatusList = [
  {
    type: ['drive_status'],
    key: driveStatus.ONGOING,
    label: 'Ongoing',
    theme: driveStatusTheme.ONGOING,
  },
  {
    type: ['drive_status'],
    key: driveStatus.SCHEDULED,
    label: 'Scheduled',
    theme: driveStatusTheme.SCHEDULED,
  },
  {
    type: ['drive_status'],
    key: driveStatus.DRAFT,
    label: 'Draft',
    theme: driveStatusTheme.DRAFT,
  },
  {
    type: ['drive_status'],
    key: driveStatus.COMPLETED,
    label: 'Completed',
    theme: driveStatusTheme.COMPLETED,
  },
  {
    type: ['drive_status'],
    key: driveStatus.CANCELLED,
    label: 'Cancelled',
    theme: driveStatusTheme.CANCELLED,
  },
  {
    type: ['drive_status'],
    key: driveStatus.INACTIVE,
    label: 'Inactive',
    theme: driveStatusTheme.INACTIVE,
  },
];

export const getFormattedDriveStatus = status => {
  if (status && (typeof status === 'string' || Object.keys(status).length)) {
    if (status?.key && status?.theme && status?.theme?.bg) {
      return status;
    }
    const parsedStatus = status && typeof status === 'string' ? status : status?.status;
    const variant = find(driveStatusList, s => lowerCase(s.key) === lowerCase((parsedStatus || '').trim() || ''));
    if (variant && variant?.label) {
      return variant;
    }
    return parsedStatus ? { ...driveStatusList[0], key: parsedStatus, label: capitalize(parsedStatus) } : null;
  }
  return status;
};
