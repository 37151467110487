import { padStart, isString } from 'lodash';
import { DateTime } from 'luxon';

export function parseExcelDate(date) {
  if (date < 25569) throw new Error('Invalid Date');
  return DateTime.fromSeconds(Math.round((date - 25569) * 86400))
    .setZone('UTC')
    .toFormat('yyyy-LL-dd');
}

export function isDateNumeric(n) {
  const regex = /$[0-9]+.[0-9]+^/i;
  const invalid = isString(n) ? !regex.test(n) : false;
  return !invalid && !Number.isNaN(Number.parseFloat(n)) && Number.isFinite(n);
}

export function parseExcelTime(serial) {
  const fractional_day = serial - Math.floor(serial) + 0.0000001;

  let total_seconds = Math.floor(86400 * fractional_day);

  const seconds = total_seconds % 60;

  total_seconds -= seconds;
  const hours = Math.floor(total_seconds / (60 * 60));
  const minutes = Math.floor(total_seconds / 60) % 60;

  return `${padStart(hours.toString(), 2, '0')}:${padStart(minutes.toString(), 2, '0')}`;
}

export function getExcelDate(date) {
  return Math.round(date / 86400) + 25569;
}

export function getFormattedDuration(date) {
  const duration = DateTime.fromISO(date).toRelative();
  if (duration) {
    return duration;
  }
  return null;
}

export function calculateTotalDays(dateString) {
  const inputDate = DateTime.fromISO(dateString);
  const currentDate = DateTime.now();
  const totalDays = currentDate.diff(inputDate, 'days').days;
  return Math.round(totalDays);
}

export function getMappedGmtTimezoneToShortName(zone) {
  if (zone === 'GMT+5:30') {
    return 'IST';
  }
  return zone;
}
