/*
 *
 * InterviewerAvailability constants
 *
 */

export const FETCH_AVAILABILITIES = 'app/InterviewerAvailability/FETCH_AVAILABILITIES';
export const STORE_AVAILABILITIES = 'app/InterviewerAvailability/STORE_AVAILABILITIES';

export const SET_TIMEZONE = 'app/InterviewerAvailability/SET_TIMEZONE';
export const STORE_PARSED_AVAILABILITIES = 'app/InterviewerAvailability/STORE_PARSED_AVAILABILITIES';
