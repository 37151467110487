import { gql } from '@apollo/client';

export const GET_USER_PROFILE_DETAILS = `
  query getCurrentUser {
    auth_user_me {
      id
      name
      username
      email
      old_id
      tenant {
        name
        id
        subdomain
      }
      phone_number
      external_id
      profile_pic_file_id
      # timezone
    }
  }
`;

export const GET_META_DATA = `
  query getMetaData {
    md_timezone(order_by: {value: asc}) {
      description
      value
    }
    sch_tenant_meeting_service_provider {
      id
      meeting_service_provider_name
    }
  }
`;

export const GET_ORG_AUTH_USERS = gql`
  query getOrgAuthUsers {
    auth_user(order_by: { id: desc }, where: { roles: { role: { _neq: CANDIDATE } } }) {
      id
      name
      username
      external_id
      email
      profile_pic_file_id
      # phone_number
      roles(distinct_on: role) {
        id
        role
      }
    }
  }
`;

export const GET_AUTH_USERS_AVAILABILITIES = gql`
  query getAuthUsersAvailabilities($payload: [sch_get_meeting_slots_input!]!) {
    sch_get_meeting_slots(payload: $payload) {
      success
      data {
        from
        to
        user_ids
        slots {
          from_date
          to_date
          user_id
        }
      }
    }
  }
`;

export const GET_AUTH_USERS_BUSY = gql`
  query getAuthUsersEvents($payload: [wft_free_busy_input!]!) {
    wft_free_busy(data: $payload) {
      success
      error_message
      data {
        id
        user_id
        from
        to
        type
        status
      }
    }
  }
`;
