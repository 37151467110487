/*
 * NotFound Messages
 *
 * This contains all the text for the NotFound component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.pages.NotFound';

export default defineMessages({
  notFound: {
    id: `${scope}.notFound`,
    defaultMessage: 'Page not found',
  },
  pageBody: {
    id: `${scope}.pageBody`,
    defaultMessage: 'Sorry, the page you’re looking for doesn’t exist. Please check',
  },
  check_URL: {
    id: `${scope}.check_URL`,
    defaultMessage: 'the URL or return to the',
  },
  homepage: {
    id: `${scope}.homepage`,
    defaultMessage: 'homepage',
  },
});
