/**
 *
 * AppNotifications
 *
 */

import { NotificationsPopover } from 'components/base/SideNavigation/NotificationsPopover';
import React, { memo /* useCallback, useContext, useEffect */ } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { error as catchGlobalError } from 'containers/App/actions';

import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
// import UserContext from 'contexts/UserContext';
// import { NotificationWrapper } from 'components/AppNotifications/NotificationWrapper';
// import { get } from 'lodash';
import reducer from './reducer';
import saga from './saga';
// import * as actions from './actions';
// import {
//   selectAllNotificationsList,
//   selectPinningEnable,
//   selectSortedNotifications,
//   selectUnreadNotificationExist,
// } from './selectors';

export function AppNotifications(props) {
  useInjectReducer({ key: 'appNotifications', reducer });
  useInjectSaga({ key: 'appNotifications', saga });

  // const { client } = useContext(UserContext);
  // const dispatch = useDispatch();
  // const data = useSelector(selectAllNotificationsList);
  // const isPinningEnable = useSelector(selectPinningEnable);
  // const sortedNotifications = useSelector(selectSortedNotifications);
  // const hasUnReadNotifications = useSelector(selectUnreadNotificationExist);
  //
  // const handleActions = useCallback((action, notificationIds) => {
  //   dispatch(action({ notificationIds }));
  // }, []);
  //
  // useEffect(() => {
  //   if (client && client.query) {
  //     dispatch(
  //       actions.startListeningNotifications({
  //         client,
  //         variables: {},
  //         callback: {
  //           onError: error => {
  //             if (get(error, 'graphQLErrors.0.extensions.code') === 'invalid-jwt') {
  //               dispatch(catchGlobalError(error));
  //             }
  //           },
  //         },
  //       }),
  //     );
  //   }
  // }, [client.query]);

  // return (
  //   <NotificationWrapper
  //     data={data}
  //     updateNotification={handleActions}
  //     actions={actions}
  //     hasUnReadNotifications={hasUnReadNotifications}
  //     isPinningEnable={isPinningEnable}
  //     sortedNotifications={sortedNotifications}
  //   />
  // );
  return <NotificationsPopover {...props} />;
}

AppNotifications.propTypes = {};

export default memo(AppNotifications);
