/* eslint no-param-reassign: "off" */
import axios from 'axios';
import { get, toNumber, isNil } from 'lodash';
import * as Sentry from '@sentry/react';
import { parseJwt } from './helpers';
import Apollo from './apollo';
import { getDisplayRole } from './data/roles';

class Auth {
  constructor() {
    this.expires = null;
    if (!Auth.instance) {
      Auth.instance = this;
    }
  }

  get isLoggedIn() {
    return !!this.graphToken;
  }

  get hasuraClaims() {
    return get(this.user, 'https://hasura.io/jwt/claims', {});
  }

  get stored() {
    let st = {};
    try {
      st = JSON.parse(localStorage.getItem('tv.wft') || '{}');
    } catch (e) {
      Sentry.captureException(e);
    }
    return st;
  }

  get graphToken() {
    return get(this.stored, 'access_token', null);
  }

  get user() {
    return get(this.stored, 'user', null);
  }

  get id() {
    return toNumber(get(this.stored, 'user.uid', null));
  }

  get accountName() {
    return get(this.stored, 'user.organization', null);
  }

  get organization() {
    return this.hasuraClaims['X-Hasura-Tenant-Id'] || 0;
  }

  get roles() {
    return get(this.stored, 'roles', null);
  }

  get role() {
    return get(this.stored, 'role', null);
  }

  async client() {
    return Apollo.createClient({ graphToken: this.graphToken, role: this.role });
  }

  removeClient() {
    Apollo.removeClient();
  }

  async createClient({ errorCallback, graphToken }) {
    await this.processToken(graphToken);
    const client = await Apollo.createClient({
      graphToken: this.graphToken,
      errorCallback,
      role: get(this.roles, '0'),
    });
    return client;
  }

  async processToken(access_token) {
    const user = parseJwt(access_token);
    const rest_token = user['rest-token'];
    const roles = user['https://hasura.io/jwt/claims']['X-Hasura-Allowed-Roles'];
    const role = getDisplayRole(roles);
    localStorage.setItem('tv.wft', JSON.stringify({ access_token, user, rest_token, role, roles }));
    this.expires = user.exp;
    Sentry.setUser({ id: user.uid });
    return user;
  }

  async logout() {
    localStorage.removeItem('tv.wft');
    await Apollo.clearCache();
    await Apollo.removeClient();
    return Promise.resolve();
  }

  async authenticate({ username, password }) {
    if (isNil(username) || isNil(password)) return Promise.reject(new Error('Invalid username/password'));
    const { data } = await axios.post('/api/auth', { username, password });
    if (!data) throw new Error('Invalid username/password');
    const access_token = get(data, 'access_token');
    await this.processToken(access_token);
    return data;
  }
}

export const instance = new Auth();

export default instance;
