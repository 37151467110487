import classNames from 'classnames';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { memo, useMemo, useState } from 'react';
import getDisplayName from 'utils/getDisplayName';
import useFetchProfilePicUrl from 'hooks/useFetchFileUrl';
import Tooltip from './AvatarTooltip';

const sizes = {
  xl: 'h-20 w-20 text-2xl',
  lg: 'h-9 w-9 text-sm',
  md: 'h-6 w-6 text-xs',
  sm: 'h-5 w-5 text-[.625rem]',
};
const Avatar = props => {
  const {
    size,
    data,
    isInitials,
    customInitial,
    showToolTip,
    role = 'avatar',
    showRing,
    isInterviewer,
    isEvaluator,
  } = props;
  const fullName = useMemo(() => (data ? getDisplayName(data) : ''), [data]);
  const [anchor, setAnchor] = useState(null);
  const [tooltip, toggleTooltip] = useState(false);
  const { url } = useFetchProfilePicUrl(data?.profile_pic_file_id);

  const initialContent = useMemo(() => {
    if (customInitial) {
      return customInitial;
    }
    if (fullName && fullName.length) {
      const names = fullName.split(' ');
      return names
        .slice(0, 2)
        .map(d => d.substring(0, 1))
        .join('');
    }
    return ((data && data.id) || '').toString().substring(0, 2);
  });

  const displayPicture = useMemo(() => (data?.profile_pic_file_id ? url : ''), [data, data?.profile_pic_file_id, url]);

  const bgColorClass = useMemo(() => {
    if (isEvaluator) return 'bg-rose-100';
    if (isInterviewer) return 'bg-purple-100';
    return 'bg-sky-200';
  }, [isEvaluator, isInterviewer]);

  if (isInitials || !displayPicture || customInitial) {
    return (
      <div
        ref={setAnchor}
        onMouseEnter={() => toggleTooltip(() => true)}
        onMouseLeave={() => toggleTooltip(() => false)}
        className={classNames(get(sizes, size, sizes.md))}
      >
        <div
          role={role}
          className={classNames(
            bgColorClass,
            'inline-flex items-center justify-center rounded-full',
            get(sizes, size, sizes.md),
            showRing && 'ring-1 ring-white',
          )}
        >
          <div className={classNames('leading-non text-gray-900 font-normal')}>{initialContent}</div>
        </div>
        {showToolTip && tooltip ? (
          <Tooltip id={`tooltip-${data?.id}`} anchor={anchor} fullName={fullName} email={data.email} />
        ) : (
          <></>
        )}
      </div>
    );
  }

  return (
    <div
      ref={setAnchor}
      onMouseEnter={() => toggleTooltip(() => true)}
      onMouseLeave={() => toggleTooltip(() => false)}
      className={classNames(get(sizes, size, sizes.md))}
    >
      <img
        role={role}
        className={classNames(
          'inline-block rounded-full object-cover',
          get(sizes, size, sizes.md),
          showRing && 'ring-1 ring-white',
        )}
        src={displayPicture}
        alt=""
        loading="lazy"
      />
      {showToolTip && tooltip ? (
        <Tooltip id={`tooltip-${data?.id}`} anchor={anchor} fullName={fullName} email={data.email} />
      ) : (
        <></>
      )}
    </div>
  );
};

Avatar.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    external_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    email: PropTypes.string,
    username: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    middle_name: PropTypes.string,
    profile_pic: PropTypes.string,
  }),
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),
  isInitials: PropTypes.bool,
  customInitial: PropTypes.string,
  showToolTip: PropTypes.bool,
  showRing: PropTypes.bool,
  isInterviewer: PropTypes.bool,
  isEvaluator: PropTypes.bool,
};

Avatar.defaultProps = {
  data: {},
  size: 'md',
  isInitials: false,
  customInitial: '',
  showToolTip: false,
  isInterviewer: false,
  isEvaluator: false,
};

export default memo(Avatar);
