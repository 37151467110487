/*
 *
 * BulkSchedule constants
 *
 */

export const INITIALIZE = 'app/BulkSchedule/INITIALIZE';
export const INITIALIZATION_COMPLETED = 'app/BulkSchedule/INITIALIZATION_COMPLETED';

export const FETCH_EVALUATORS = 'app/BulkSchedule/FETCH_EVALUATORS';
export const STORE_FETCHED_EVALUATORS = 'app/BulkSchedule/STORE_FETCHED_EVALUATORS';

export const FETCH_TIMEZONES = 'app/BulkSchedule/FETCH_TIMEZONES';
export const STORE_FETCHED_TIMEZONES = 'app/BulkSchedule/STORE_FETCHED_TIMEZONES';

export const FETCH_PROVIDER = 'app/BulkSchedule/FETCH_PROVIDER';
export const STORE_FETCHED_PROVIDER = 'app/BulkSchedule/STORE_FETCHED_PROVIDER';

export const STORE_SLOT_CONFLICT = 'app/BulkSchedule/STORE_SLOT_CONFLICT';
export const UPDATE_SELECTED_DURATION = 'app/BulkSchedule/UPDATE_SELECTED_DURATION';

export const ADD_SELECTED_EVALUATOR = 'app/BulkSchedule/ADD_SELECTED_EVALUATOR';

export const ADD_SELECTED_CANDIDATE = 'app/BulkSchedule/ADD_SELECTED_CANDIDATE';

export const ADD_BULK_SLOTS = 'app/BulkSchedule/ADD_BULK_SLOTS';
export const UPDATE_BULK_SLOTS = 'app/BulkSchedule/UPDATE_BULK_SLOTS';
export const UPDATE_SLOT = 'app/BulkSchedule/UPDATE_SLOT';
export const REMOVE_SLOT = 'app/BulkSchedule/REMOVE_SLOT';
export const STORE_UPDATED_SLOT = 'app/BulkSchedule/STORE_UPDATED_SLOT';

export const PARSE_FILE_UPLOAD_DATA = 'app/BulkSchedule/PARSE_FILE_UPLOAD_DATA';
export const STORE_FILE_UPLOAD_DATA = 'app/BulkSchedule/STORE_FILE_UPLOAD_DATA';

export const STORE_CALENDAR_SCHEDULE = 'app/BulkSchedule/STORE_CALENDAR_SCHEDULE';
export const FETCH_CALENDAR_SLOT = 'app/BulkSchedule/FETCH_CALENDAR_SLOT';

export const STORE_SLOTS = 'app/BulkSchedule/STORE_SLOTS';
export const FETCH_SLOT = 'app/BulkSchedule/FETCH_SLOT';
