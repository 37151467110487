/**
 *
 * Maintenance
 *
 */

import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import Img from 'images/500.svg';
import Logo from 'images/talview_dark.png';
import Button from 'components/base/Button/newButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsRotate } from '@fortawesome/pro-solid-svg-icons';

import messages from './messages';

export function Maintenance() {
  return (
    <div className="h-screen bg-slate-50 ">
      <div className="h-full flex justify-center items-center ">
        <div className="md:p-20 flex shadow-lg m-4 sm:m-0 items-center p-6 rounded-md bg-white">
          <div className="flex flex-col space-y-8 ">
            <img src={Logo} className="w-32 " alt="logo" />
            <h2 className="flex lg:text-4xl text-2xl leading-15 font-">
              <FormattedMessage {...messages.header} />
            </h2>
            <p className="text-base md:text-lg">
              <FormattedMessage {...messages.body} />
            </p>
            <div className="max-w-xl w-[186px]">
              <Button
                type="ghost"
                size="lg"
                isFullWidth
                leadingIcon={<FontAwesomeIcon icon={faArrowsRotate} />}
                onClick={() => window.location.reload()}
              >
                <FormattedMessage {...messages.update} />
              </Button>
            </div>
          </div>
          <img src={Img} alt="maintenance" className="md:max-w-sm lg:max-w-lg md:h-full hidden md:block" />
        </div>
      </div>
    </div>
  );
}

Maintenance.propTypes = {};

export default memo(Maintenance);
