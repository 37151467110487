import { defineMessages } from 'react-intl';

export const scope = 'app.components.base.Checkbox';

export default defineMessages({
  Draft: {
    id: `${scope}.draft`,
    defaultMessage: 'Draft',
  },
  Open: {
    id: `${scope}.open`,
    defaultMessage: 'Open',
  },
  Filled: {
    id: `${scope}.filled`,
    defaultMessage: 'Filled',
  },
  Cancelled: {
    id: `${scope}.cancelled`,
    defaultMessage: 'Cancelled',
  },
  Suspended: {
    id: `${scope}.suspended`,
    defaultMessage: 'Suspended',
  },
  Invited: {
    id: `${scope}.invited`,
    defaultMessage: 'Invited',
  },
  Accepted: {
    id: `${scope}.accepted`,
    defaultMessage: 'Accepted',
  },
  Tentative: {
    id: `${scope}.tentative`,
    defaultMessage: 'Tentative',
  },
  'No Response': {
    id: `${scope}.no_response`,
    defaultMessage: 'No Response',
  },
  'Proposed new time': {
    id: `${scope}.proposed_new_time`,
    defaultMessage: 'Proposed new time',
  },
  Declined: {
    id: `${scope}.declined`,
    defaultMessage: 'Declined',
  },
  'Declined new time proposed': {
    id: `${scope}.declined_new_time_proposed`,
    defaultMessage: 'Declined new time proposed',
  },
  'Not joined': {
    id: `${scope}.not_joined`,
    defaultMessage: 'Not joined',
  },
  Waiting: {
    id: `${scope}.waiting`,
    defaultMessage: 'Waiting',
  },
  Joined: {
    id: `${scope}.joined`,
    defaultMessage: 'Joined',
  },
  Completed: {
    id: `${scope}.completed`,
    defaultMessage: 'Completed',
  },
  'No show': {
    id: `${scope}.no_show`,
    defaultMessage: 'No show',
  },
  // meeting status
  "Couldn't allocate": {
    id: `${scope}.could_not_allocate`,
    defaultMessage: "Couldn't allocate",
  },
  Scheduled: {
    id: `${scope}.scheduled`,
    defaultMessage: 'Scheduled',
  },
  'In progress': {
    id: `${scope}.in_progress`,
    defaultMessage: 'In progress',
  },
});
