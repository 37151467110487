/*
 * ChangeLanguage Messages
 *
 * This contains all the text for the ChangeLanguage component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.ChangeLanguage';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'Language Settings',
  },
  change_language: {
    id: `${scope}.change_language`,
    defaultMessage: 'Change Language',
  },
  cancel: {
    id: `${scope}.cancel`,
    defaultMessage: 'Cancel',
  },
  submit: {
    id: `${scope}.submit`,
    defaultMessage: 'Save Changes',
  },
  change_lang: {
    id: `${scope}.change_lang`,
    defaultMessage: 'Change Language*',
  },
});
