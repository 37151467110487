import { capitalize, find, lowerCase } from 'lodash';

export const recommendationStatusTheme = {
  selected: { bg: 'bg-green-50', text: 'text-green-900' },
  turned_down: { bg: 'bg-red-50', text: 'text-red-600' },
  hold: { bg: 'bg-yellow-50', text: 'text-yellow-800' },
  suspended: { bg: 'bg-yellow-50', text: 'text-yellow-900' },
};

export const recommendationStatus = {
  SELECTED: 'SELECTED',
  TURNED_DOWN: 'TURNED_DOWN',
  HOLD: 'HOLD',
  SUSPENDED: 'SUSPENDED',
};

export const recommendationStatusList = [
  {
    type: ['recommendation_status'],
    key: recommendationStatus.SELECTED,
    label: 'Selected',
    theme: recommendationStatusTheme.selected,
  },
  {
    type: ['recommendation_status'],
    key: recommendationStatus.TURNED_DOWN,
    label: 'Turned down',
    theme: recommendationStatusTheme.turned_down,
  },
  {
    type: ['recommendation_status'],
    key: recommendationStatus.HOLD,
    label: 'Hold',
    theme: recommendationStatusTheme.hold,
  },
  {
    type: ['recommendation_status'],
    key: recommendationStatus.SUSPENDED,
    label: 'Suspended',
    theme: recommendationStatusTheme.suspended,
  },
];

export const getFormattedRecommendationStatus = status => {
  if (status && (typeof status === 'string' || Object.keys(status).length)) {
    if (status?.key && status?.theme && status?.theme?.bg) {
      return status;
    }
    let parsedStatus = status && typeof status === 'string' ? status : status?.recommendation_status;
    if (lowerCase(parsedStatus || '') === 'rejected') {
      parsedStatus = recommendationStatus.TURNED_DOWN;
    }
    const variant = find(
      recommendationStatusList,
      s => lowerCase(s.key) === lowerCase((parsedStatus || '').trim() || ''),
    );
    if (variant && variant?.label) {
      return variant;
    }
    return parsedStatus ? { ...recommendationStatusList[2], key: parsedStatus, label: capitalize(parsedStatus) } : null;
  }
  return status;
};
