import { authenticateToken } from 'containers/auth/LoginPage/api';
import { eventChannel } from 'redux-saga';
import Auth from 'utils/auth';
import { app, auth } from 'utils/firebase';

export const createEventChannel = () =>
  eventChannel(emitter => {
    const unsubscribe = auth.onAuthStateChanged(user => emitter({ user }));
    return () => unsubscribe();
  });

export async function init() {
  const search = new URLSearchParams(window.location.search);
  if (search.get('accessToken')) {
    await Auth.logout();
    await authenticateToken({ access_token: search.get('accessToken') });
    search.delete('accessToken');
    window.location.search = search.toString();
  }
  return app;
}
