import { defineMessages } from 'react-intl';

export const scope = 'app.components.base.SideNavigation.NavigationIcon';
export default defineMessages({
  'Get Help': {
    id: `${scope}.get_help`,
    defaultMessage: 'Get Help',
  },
  'More Apps': {
    id: `${scope}.more_apps`,
    defaultMessage: 'More Apps',
  },
  Notifications: {
    id: `${scope}.notifications`,
    defaultMessage: 'Notifications',
  },
});
