/*
 *
 * AppNotifications reducer
 *
 */
import { produce } from 'immer';
import { forEach } from 'lodash';
import {
  STORE_WORKFLOW_ACTIVITIES,
  MARK_READ,
  MARK_UNREAD,
  MARK_PIN,
  MARK_UNPIN,
  MARK_DELETE,
  MARK_ALL_READ,
  ACTION_INITIATED,
  UPDATE_INITIATED_ACTION,
} from './constants';

export const initialState = {
  isLoading: true,
  data: {},
  filteredData: {},
  initiatedClientSideActions: [],
};

/* eslint-disable default-case, no-param-reassign */
const appNotificationsReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case STORE_WORKFLOW_ACTIVITIES: {
        draft.isLoading = false;
        if (action.payload && Object.keys(action.payload).length) {
          Object.keys(action.payload).forEach(key => {
            if (draft.data[key] && Object.keys(draft.data[key]).length) {
              draft.data[key] = {
                ...draft.data[key],
                ...action.payload[key],
              };
            } else {
              draft.data[key] = action.payload[key];
            }
          });
        }
        break;
      }
      case MARK_READ: {
        if (action?.notificationIds && Array.isArray(action.notificationIds) && action.notificationIds.length) {
          forEach(action.notificationIds, notificationId => {
            draft.data[notificationId].read = true;
          });
        }
        break;
      }
      case MARK_UNREAD: {
        if (action?.notificationIds && Array.isArray(action.notificationIds) && action.notificationIds.length) {
          forEach(action.notificationIds, notificationId => {
            draft.data[notificationId].read = false;
          });
        }
        break;
      }
      case MARK_PIN: {
        if (action?.notificationIds && Array.isArray(action.notificationIds) && action.notificationIds.length) {
          forEach(action.notificationIds, notificationId => {
            draft.data[notificationId].pinned = true;
          });
        }
        break;
      }
      case MARK_UNPIN: {
        if (action?.notificationIds && Array.isArray(action.notificationIds) && action.notificationIds.length) {
          forEach(action.notificationIds, notificationId => {
            draft.data[notificationId].pinned = false;
          });
        }
        break;
      }
      case MARK_DELETE: {
        if (action?.notificationIds && Array.isArray(action.notificationIds) && action.notificationIds.length) {
          forEach(action.notificationIds, notificationId => {
            draft.data[notificationId].deleted = true;
          });
        }
        break;
      }
      case MARK_ALL_READ: {
        if (draft.data && Object.keys(draft.data).length) {
          forEach(Object.keys(draft.data), key => {
            draft.data[key].read = true;
          });
        }
        break;
      }
      case ACTION_INITIATED: {
        draft.initiatedClientSideActions.push({
          response_id: action?.response_id,
          read: false,
          pinned: false,
          deleted: false,
          actionType: action?.actionType,
          payload: action?.payload,
          key: action?.key,
          responseQuery: action?.responseQuery,
          inProgress: true,
          response: action?.response,
          error: action?.error,
          additionalContext: action?.additionalContext,
        });
        break;
      }
      case UPDATE_INITIATED_ACTION: {
        const index = draft.initiatedClientSideActions.findIndex(a => a.response_id === action.response_id);
        if (index !== -1) {
          draft.initiatedClientSideActions[index].inProgress = !(action?.error || action?.response);
          draft.initiatedClientSideActions[index].response = action?.response;
          draft.initiatedClientSideActions[index].error = action?.error;
        }
        break;
      }
    }
  });

export default appNotificationsReducer;
