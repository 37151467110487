import { filter, find } from 'lodash';
import { createSelector } from 'reselect';
import { ROLE_EVALUATOR } from 'utils/data/roles';
import { initialState } from './reducer';

// const selectGlobal = (state) => state.global || initialState;

const selectApp = state => state.App || initialState;

const selectTasks = state => state || [];

const selectSaved = state => state.saved || initialState;

const selectAppState = createSelector(selectApp, state => state);

const selectUser = createSelector(selectApp, state => state.user);
const selectCountry = createSelector(selectApp, state => state.country);

const makeSelectError = () => createSelector(selectApp, state => state.error);

const makeSelectTasks = () => createSelector(selectTasks, state => state);

const makeSelectEnv = () => createSelector(selectApp, state => state.env);

const makeSelectApp = () => createSelector(selectApp, state => state);

const makeSelectSaved = () => createSelector(selectSaved, savedState => savedState);

const selectMetaData = createSelector(selectApp, state => {
  if (state?.user?.old_id) {
    return state?.meta;
  }
  return {
    ...state?.meta,
    providers: state?.meta?.providers,
  };
});

const selectAuthEvaluators = createSelector(selectMetaData, ({ authUsers = [] }) =>
  filter(authUsers, authUser => find(authUser.roles, ['role', ROLE_EVALUATOR]) && authUser?.email),
);

export {
  makeSelectTasks,
  makeSelectError,
  makeSelectEnv,
  makeSelectSaved,
  makeSelectApp,
  selectCountry,
  selectUser,
  selectMetaData,
  selectAuthEvaluators,
  selectAppState,
};
