/* eslint-disable max-len */
/*
 * ActionTypes Messages
 *
 * This contains all the text for the ActionTypes component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.utils.actions.actionTypes';

export default defineMessages({
  REINVITE_CANDIDATE: {
    id: `${scope}.REINVITE_CANDIDATE`,
    defaultMessage:
      '{value, plural, =0 {{value} candidate reinvited} one {{value} candidate reinvited} other {{value} candidates reinvited}}',
  },
  INVITE_CANDIDATE: {
    id: `${scope}.INVITE_CANDIDATE`,
    defaultMessage:
      '{value, plural, =0 {{value} candidate invited} one {{value} candidate invited} other {{value} candidates invited}}',
  },
  SCHEDULE_INTERVIEW: {
    id: `${scope}.SCHEDULE_INTERVIEW`,
    defaultMessage:
      '{value, plural, =0 {{value} interview scheduled} one {{value} interview scheduled} other {{value} interviews scheduled}}',
  },
  PUBLISH_WORKFLOW: {
    id: `${scope}.PUBLISH_WORKFLOW`,
    defaultMessage: 'Workflow published',
  },
  REMIND_CANDIDATE: {
    id: `${scope}.REMIND_CANDIDATE`,
    defaultMessage:
      '{value, plural, =0 {{value} candidate reminded} one {{value} candidate reminded} other {{value} candidates reminded}}',
  },
  EXTEND_EVALUATION: {
    id: `${scope}.EXTEND_EVALUATION`,
    defaultMessage:
      '{value, plural, =0 {{value} evaluation date extended} one {{value} evaluation date extended} other {{value} evaluation dates extended}}',
  },
  UPDATE_JOBDESCRIPTION: {
    id: `${scope}.UPDATE_JOBDESCRIPTION`,
    defaultMessage: 'Description updated',
  },
  MOVE_CANDIDATE: {
    id: `${scope}.MOVE_CANDIDATE`,
    defaultMessage:
      '{value, plural, =0 {{value} candidate moved} one {{value} candidate moved} other {{value} candidates moved}}',
  },
  ASSIGN_PANEL: {
    id: `${scope}.ASSIGN_PANEL_NEW`,
    defaultMessage:
      '{value, plural, =0 {{value} evaluator assigned} one {{value} evaluator assigned} other {{value} evaluators assigned}}',
  },
  REMIND_PANEL: {
    id: `${scope}.REMIND_PANEL_NEW`,
    defaultMessage:
      '{value, plural, =0 {{value} evaluator reminded} one {{value} evaluator reminded} other {{value} evaluators reminded}}',
  },
  EXTEND_LASTDATE: {
    id: `${scope}.EXTEND_LASTDATE`,
    defaultMessage:
      '{value, plural, =0 {{value} candidate provided extension} one {{value} candidate provided extension} other {{value} candidates provided extension}}',
  },
  CANCEL_INTERVIEW: {
    id: `${scope}.CANCEL_INTERVIEW`,
    defaultMessage:
      '{value, plural, =0 {{value} interview canceled} one {{value} interview canceled} other {{value} interviews canceled}}',
  },
  RESCHEDULE_INTERVIEW: {
    id: `${scope}.RESCHEDULE_INTERVIEW`,
    defaultMessage:
      '{value, plural, =0 {{value} interview rescheduled} one {{value} interview rescheduled} other {{value} interviews rescheduled}}',
  },
  ASSIGN_INTERVIEW_PANEL: {
    id: `${scope}.ASSIGN_INTERVIEW_PANEL`,
    defaultMessage:
      '{value, plural, =0 {{value} candidate assigned interviewers} one {{value} candidate assigned interviewers} other {{value} candidates assigned interviewers}}',
  },
  UNPUBLISH_WORKFLOW: {
    id: `${scope}.UNPUBLISH_WORKFLOW`,
    defaultMessage: 'Workflow unpublished',
  },
  CREATE_WORKFLOW: {
    id: `${scope}.CREATE_WORKFLOW`,
    defaultMessage: 'Workflow created',
  },
  ADD_COLLABORATORS: {
    id: `${scope}.ADD_COLLABORATORS`,
    defaultMessage: 'Collaborator added',
  },
  ASSIGN_EVALUATOR: {
    id: `${scope}.ASSIGN_EVALUATOR`,
    defaultMessage: 'Assign Evaluator',
  },
  REMOVE_COLLABORATORS: {
    id: `${scope}.REMOVE_COLLABORATORS`,
    defaultMessage: 'Collaborator removed',
  },
  scheduling_progress: {
    id: `${scope}.scheduling_progress`,
    defaultMessage: 'Scheduling in progress',
  },
  single_candidate: {
    id: `${scope}.single_candidate`,
    defaultMessage: 'Candidate is',
  },
  multiple_candidates: {
    id: `${scope}.multiple_candidates`,
    defaultMessage: 'Candidates are',
  },
  scheduling: {
    id: `${scope}.scheduling`,
    defaultMessage: 'Scheduling for',
  },
  against: {
    id: `${scope}.against`,
    defaultMessage: 'against',
  },
  completed_success: {
    id: `${scope}.completed_success`,
    defaultMessage: ' is completed successfully',
  },
  notify: {
    id: `${scope}.notify`,
    defaultMessage: 'We’ll notify you once it’s done.',
  },
  scheduled_background: {
    id: `${scope}.scheduled_background`,
    defaultMessage: 'scheduled in the background against',
  },
  scheduling_successful: {
    id: `${scope}.scheduling_successful`,
    defaultMessage: 'Scheduling completed successfully!',
  },
  'Workflow created': {
    id: `${scope}.Workflow_created`,
    defaultMessage: 'Workflow created',
  },
  'Collaborator added': {
    id: `${scope}.Collaborator_added`,
    defaultMessage: 'Collaborator added',
  },
  'Collaborator removed': {
    id: `${scope}.Collaborator_removed`,
    defaultMessage: 'Collaborator removed',
  },
  'Candidates reinvited': {
    id: `${scope}.Candidates_reinvited`,
    defaultMessage: 'Candidates reinvited',
  },
  'Candidate reinvited': {
    id: `${scope}.Candidate_reinvited`,
    defaultMessage: 'Candidate reinvited',
  },
  'Candidates invited': {
    id: `${scope}.Candidates_invited`,
    defaultMessage: 'Candidates invited',
  },
  'Candidate invited': {
    id: `${scope}.Candidate_invited`,
    defaultMessage: 'Candidate invited',
  },
  'Invite in progress': {
    id: `${scope}.Invite_in_progress`,
    defaultMessage: 'Invite in progress',
  },
  'Invite failed!': {
    id: `${scope}.Invite_failed`,
    defaultMessage: 'Invite failed!',
  },
  'Interviews scheduled': {
    id: `${scope}.Interviews_scheduled`,
    defaultMessage: 'Interviews scheduled',
  },
  'Interview scheduled': {
    id: `${scope}.Interview_scheduled`,
    defaultMessage: 'Interview scheduled',
  },
  'Workflow published': {
    id: `${scope}.Workflow_published`,
    defaultMessage: 'Workflow published',
  },
  'Candidates reminded': {
    id: `${scope}.Candidates_reminded`,
    defaultMessage: 'Candidates reminded',
  },
  'Candidate reminded': {
    id: `${scope}.Candidate_reminded`,
    defaultMessage: 'Candidate reminded',
  },
  'Evaluation dates extended': {
    id: `${scope}.Evaluation_dates_extended`,
    defaultMessage: 'Evaluation dates extended',
  },
  'Evaluation date extended': {
    id: `${scope}.Evaluation_date_extended`,
    defaultMessage: 'Evaluation date extended',
  },
  'Description updated': {
    id: `${scope}.Description_updated`,
    defaultMessage: 'Description updated',
  },
  'Candidates moved': {
    id: `${scope}.Candidates_moved`,
    defaultMessage: 'Candidates moved',
  },
  'Candidate moved': {
    id: `${scope}.Candidate_moved`,
    defaultMessage: 'Candidate moved',
  },
  'Evaluators assigned': {
    id: `${scope}.Evaluators_assigned`,
    defaultMessage: 'Evaluators assigned',
  },
  'Evaluator assigned': {
    id: `${scope}.Evaluator_assigned`,
    defaultMessage: 'Evaluator assigned',
  },
  'Evaluators reminded': {
    id: `${scope}.Evaluators_reminded`,
    defaultMessage: 'Evaluators reminded',
  },
  'Evaluator reminded': {
    id: `${scope}.Evaluator_reminded`,
    defaultMessage: 'Evaluator reminded',
  },
  'Candidates provided extension': {
    id: `${scope}.Candidates_provided_extension`,
    defaultMessage: 'Candidates provided extension',
  },
  'Candidate provided extension': {
    id: `${scope}.Candidate_provided_extension`,
    defaultMessage: 'Candidate provided extension',
  },
  'Interviews canceled': {
    id: `${scope}.Interviews_canceled`,
    defaultMessage: 'Interviews canceled',
  },
  'Interview canceled': {
    id: `${scope}.Interview_canceled`,
    defaultMessage: 'Interview canceled',
  },
  'Interviews rescheduled': {
    id: `${scope}.Interviews_rescheduled`,
    defaultMessage: 'Interviews rescheduled',
  },
  'Interview rescheduled': {
    id: `${scope}.Interview_rescheduled`,
    defaultMessage: 'Interview rescheduled',
  },
  'Candidates assigned interviewers': {
    id: `${scope}.Candidates_assigned_interviewers`,
    defaultMessage: 'Candidates assigned interviewers',
  },
  'Candidate assigned interviewers': {
    id: `${scope}.Candidate_assigned_interviewers`,
    defaultMessage: 'Candidate assigned interviewers',
  },
  'Workflow unpublished': {
    id: `${scope}.Workflow_unpublished`,
    defaultMessage: 'Workflow unpublished',
  },
});
