/**
 *
 * TopNavBar
 *
 */

import React, { memo } from 'react';
import Breadcrumb from 'components/base/Breadcrumb';

export function TopNavBar() {
  return (
    <div className="flex items-center justify-between px-3 py-3 bg-white">
      <Breadcrumb />
      <div id="top-nav-bar-left-container" />
      <div id="top-nav-bar-right-container" />
    </div>
  );
}

export default memo(TopNavBar);
