/**
 *
 * ReportIssue
 *
 */

import { ConfirmDialog } from 'components/base/ConfirmDialog';
import React, { memo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import Content from './Content';

export function ReportIssue(props) {
  const { open, handleClose, handleOnSuccess } = props;
  const [isLoading, toggleLoading] = useState(false);
  const [successView, toggleSuccessView] = useState(false);

  const onClose = () => {
    if (!isLoading && handleClose) {
      handleClose();
    }
  };

  return (
    <ConfirmDialog
      open={open}
      handleClose={onClose}
      title={!successView && <FormattedMessage {...messages.title} />}
      isCustomContent
      customContent={
        <Content
          handleClose={handleClose}
          handleOnSuccess={handleOnSuccess}
          toggleLoading={toggleLoading}
          isLoading={isLoading}
          successView={successView}
          toggleSuccessView={toggleSuccessView}
        />
      }
    />
  );
}

ReportIssue.propTypes = {};

export default memo(ReportIssue);
