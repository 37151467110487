/*
 *
 * Workflow actions
 *
 */

import { FETCH_JOB_DATA, PARSE_TABLE_STATE, UPDATE_TABLE_STATE } from './constants';

export function parseTableState() {
  return {
    type: PARSE_TABLE_STATE,
  };
}

export function refreshWorkflowData() {
  return {
    type: FETCH_JOB_DATA,
    forceRefresh: true,
  };
}

export function updateTableState({ tableState }) {
  return {
    type: UPDATE_TABLE_STATE,
    tableState,
  };
}
