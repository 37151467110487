/*
 *
 * WorkflowList constants
 *
 */

export const FETCH_JOB_DATA = 'app/WORKFLOW_LIST/FETCH_JOB_DATA';
export const STORE_JOB_DATA = 'app/WORKFLOW_LIST/STORE_JOB_DATA';
export const ERROR_ON_FETCH_JOB_DATA = 'app/WORKFLOW_LIST/ERROR_ON_FETCH_JOB_DATA';
export const UPDATE_TABLE_STATE = 'app/WORKFLOW_LIST/UPDATE_TABLE_STATE';
export const PARSE_TABLE_STATE = 'app/WORKFLOW_LIST/PARSE_TABLE_STATE';
export const STORE_TABLE_STATE = 'app/WORKFLOW_LIST/STORE_TABLE_STATE';
export const STORE_JOB_ACTIVITY_DATA = 'app/WORKFLOW_LIST/STORE_JOB_ACTIVITY_DATA';
