import { filter, find, reverse, sortBy } from 'lodash';
import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the appNotifications state domain
 */

const selectAppNotificationsDomain = state => state.appNotifications || initialState;

/**
 * Other specific selectors
 */

const selectAllNotifications = createSelector(selectAppNotificationsDomain, state => state.data);
const selectAllNotificationsList = createSelector(selectAppNotificationsDomain, state => {
  if (state.data && Object.keys(state.data).length) {
    const data = filter(Object.values(state.data), d => !d.deleted);
    return reverse(sortBy(data, 'id'));
  }
  return [];
});

const selectUnReadNotifications = createSelector(selectAllNotificationsList, state => {
  if (state && Array.isArray(state) && state.length) {
    return filter(state, d => !d.read);
  }
  return [];
});

const selectPinnedNotifications = createSelector(selectAllNotificationsList, state => {
  if (state && Array.isArray(state) && state.length) {
    return filter(state, d => d.pinned);
  }
  return [];
});

const selectPinningEnable = createSelector(selectPinnedNotifications, state => {
  if (state && Array.isArray(state) && state.length) {
    return state.length < 3;
  }
  return true;
});

const selectUnreadNotificationExist = createSelector(selectUnReadNotifications, state => {
  if (state && Array.isArray(state)) {
    return state.length > 0;
  }
  return false;
});

const selectSortedNotifications = createSelector(selectAllNotificationsList, state => {
  const pinnedRecords = filter(state, d => d.pinned);
  const unPinnedRecords = filter(state, d => !d.pinned);
  return [...pinnedRecords, ...unPinnedRecords];
});

const selectStoredInitiatedActions = createSelector(
  selectAppNotificationsDomain,
  state => state.initiatedClientSideActions,
);
const selectResponseId = (state, responseId) => responseId;
const selectStoredInitiatedActionsByResponseId = createSelector(
  selectStoredInitiatedActions,
  selectResponseId,
  (state, responseId) => find(state, ['response_id', responseId]),
);
/**
 * Default selector used by AppNotifications
 */

export {
  selectAppNotificationsDomain,
  selectAllNotifications,
  selectAllNotificationsList,
  selectUnReadNotifications,
  selectPinnedNotifications,
  selectPinningEnable,
  selectSortedNotifications,
  selectUnreadNotificationExist,
  selectStoredInitiatedActionsByResponseId,
};
