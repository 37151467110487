import { concat, sortBy } from 'lodash';
import XLSX from 'xlsx';

export const exportToExcel = async ({ columns, rows, name }) => {
  const ws = XLSX.utils.aoa_to_sheet(concat([columns], rows));
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Export');
  /* generate XLSX file and send to client */
  return XLSX.writeFile(wb, `${name}.xlsx`, { Props: { Author: 'Talview' } });
};

// Function to generate the formatted data using excelColumns and data
export const generateFormattedData = (excelC, data) =>
  data.map(row => {
    const formattedRow = [];
    excelC.forEach(column => {
      formattedRow.push(column.getValue(row));
    });
    return formattedRow;
  });

// Function to generate the Excel columns
export const generateExcelColumns = columns => sortBy(columns, 'sortKey').map(column => column.label);

export default exportToExcel;
