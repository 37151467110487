import { capitalize, find, lowerCase } from 'lodash';
import { stepTypes } from './stepTypes';

export const meetingStatus = {
  DRAFT: 'DRAFT',
  SCHEDULED: 'SCHEDULED',
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETED: 'COMPLETED',
  NO_SHOW: 'NO_SHOW',
  CANCELLED: 'CANCELLED',
  ONGOING: 'ONGOING',
  CANNOT_ALLOCATE: 'CANNOT_ALLOCATE',
  // ADDED: 'ADDED',
};

export const meetingStatusList = [
  {
    type: ['meeting_status', stepTypes.INTERVIEW],
    key: meetingStatus.SCHEDULED,
    label: 'Scheduled',
    theme: {
      bg: 'bg-purple-50',
      text: 'text-purple-800 ',
    },
  },
  {
    type: ['meeting_status', stepTypes.INTERVIEW],
    key: meetingStatus.CANCELLED,
    label: 'Cancelled',
    theme: {
      bg: 'bg-red-50',
      text: 'text-red-600',
    },
  },
  // {
  //   type: ['meeting_status', stepTypes.INTERVIEW],
  //   value: 39,
  //   key: meetingStatus.ONGOING,
  //   label: 'Ongoing',
  //   theme: {
  //     bg: 'bg-green-100',
  //     text: 'text-green-900',
  //   },
  // },
  {
    type: ['meeting_status', stepTypes.INTERVIEW],
    key: meetingStatus.IN_PROGRESS,
    label: 'In progress',
    theme: {
      bg: 'bg-orange-100',
      text: 'text-orange-700',
    },
  },
  {
    type: ['meeting_status', stepTypes.INTERVIEW],
    key: meetingStatus.DRAFT,
    label: 'Draft',
    theme: {
      bg: 'bg-gray-50',
      text: 'text-gray-900',
    },
  },
  {
    type: ['meeting_status', stepTypes.INTERVIEW],
    key: meetingStatus.NO_SHOW,
    label: 'No show',
    theme: {
      bg: 'bg-orange-50',
      text: 'text-orange-600',
    },
  },
  {
    type: ['meeting_status', stepTypes.INTERVIEW],
    key: meetingStatus.COMPLETED,
    label: 'Completed',
    theme: {
      bg: 'bg-blue-50',
      text: 'text-blue-900',
    },
  },
  {
    type: ['meeting_status', stepTypes.INTERVIEW],
    key: meetingStatus.CANNOT_ALLOCATE,
    label: "Couldn't allocate",
    theme: {
      bg: '',
      text: 'text-red-500',
    },
  },
  // {
  //   type: ['meeting_status', stepTypes.SCREENING],
  //   key: meetingStatus.ADDED,
  //   label: 'Added',
  //   theme: {
  //     bg: 'bg-accent-50',
  //     text: 'text-accent-700 ',
  //   },
  // },
];

export const getFormattedMeetingStatus = status => {
  if (status && (typeof status === 'string' || Object.keys(status).length)) {
    if (status?.key && status?.theme && status?.theme?.bg) {
      return status;
    }
    let parsedStatus = status && typeof status === 'string' ? status : status?.meeting_status;
    if (lowerCase(parsedStatus || '') === 'inprogress') {
      parsedStatus = meetingStatus.IN_PROGRESS;
    }
    if (lowerCase(parsedStatus || '') === 'noshow') {
      parsedStatus = meetingStatus.NO_SHOW;
    }

    const variant = find(meetingStatusList, s => lowerCase(s.key) === lowerCase((parsedStatus || '').trim() || ''));

    if (variant && variant?.label) {
      return variant;
    }
    return parsedStatus ? { ...meetingStatusList[0], key: parsedStatus, label: capitalize(parsedStatus) } : null;
  }
  return status;
};
