/*
 * NavModules Messages
 *
 * This contains all the text for the NavModules component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.NavModules';

export default defineMessages({
  dashboard: {
    id: `${scope}.dashboard`,
    defaultMessage: 'Dashboard',
  },
  workflows: {
    id: `${scope}.workflows`,
    defaultMessage: 'Workflows',
  },
  evaluations: {
    id: `${scope}.evaluations`,
    defaultMessage: 'Evaluations',
  },
  candidates: {
    id: `${scope}.candidates`,
    defaultMessage: 'Candidates',
  },
  interviews: {
    id: `${scope}.interviews`,
    defaultMessage: 'Interviews',
  },
  assessments: {
    id: `${scope}.assessments`,
    defaultMessage: 'Assessments',
  },
  admin: {
    id: `${scope}.admin`,
    defaultMessage: 'Admin',
  },
  availability: {
    id: `${scope}.availability`,
    defaultMessage: 'Availability',
  },
  moreApps: {
    id: `${scope}.moreApps`,
    defaultMessage: 'More Apps',
  },
  inbox: {
    id: `${scope}.inbox`,
    defaultMessage: 'Inbox',
  },
});
