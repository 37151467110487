/**
 *
 * UserProvider
 *
 */

import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

const UserContext = React.createContext({
  client: {},
  user: {},
});

const getClient = async (client, callback) => {
  const res = await client;
  callback(res);
};

function UserProvider(props) {
  const { children, client, user } = props;
  const [apolloClient, setClient] = useState(client);
  const value = useMemo(
    () => ({
      client: apolloClient,
      user,
    }),
    [apolloClient, user],
  );

  useEffect(() => {
    getClient(client, setClient);
  }, [client]);

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}
UserProvider.propTypes = {
  client: PropTypes.object,
  user: PropTypes.object,
  children: PropTypes.any,
};

UserProvider.defaultProps = {
  client: {},
  user: {},
};
export const UserConsumer = UserContext.Consumer;

export { UserProvider };

export default UserContext;
