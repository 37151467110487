import { find } from 'lodash';
import React, { useContext, useMemo, useState } from 'react';
import { triggerToast } from 'components/base/Notification';
import { Dropdown } from 'components/Dropdown';
import { updateTimeZone } from 'containers/App/actions';
import Button from 'components/base/Button/newButton';
import ActionContext from 'contexts/ActionContext';
import { FormattedMessage } from 'react-intl';
import AuthContext from 'contexts/AuthContext';
import { DateTime } from 'luxon';
import messages from './messages';

const TimeZones = () => {
  const { user } = useContext(AuthContext);
  const {
    state: { timezones },
    dispatch,
  } = useContext(ActionContext);

  const defaultTimezone = useMemo(() => {
    const { zoneName } = DateTime.local();
    if ((zoneName || '').toLowerCase().includes('calcutta')) {
      return { description: 'Asia/Kolkata', value: 'Asia/Kolkata' };
    }
    return find(timezones, ['value', zoneName]) || { description: zoneName, value: zoneName };
  }, []);

  const [selectedTimezone, setSelectedTimezone] = useState(user?.timezone || defaultTimezone);

  const handleClick = () => {
    const userId = user?.id;
    dispatch(updateTimeZone({ timezone: selectedTimezone, userId }));
    triggerToast({
      variant: 'success',
      setting: { position: 'top-right' },
      message: {
        title: <FormattedMessage {...messages.timezone_success} />,
        summary: <FormattedMessage {...messages.timezone_success_summary} />,
      },
    });
  };

  const disabled = useMemo(() => {
    if (user?.timezone?.value) return selectedTimezone?.value === user?.timezone?.value;
    return selectedTimezone?.value === defaultTimezone?.value;
  }, [user?.timezone?.value, selectedTimezone?.value, defaultTimezone?.value]);

  return (
    <div className="w-full p-6">
      <div className="pb-6 font-medium text-base leading-5 text-gray-900">
        <FormattedMessage {...messages.timezone} />
      </div>
      <div className="mb-4 font-normal leading-4 text-sm text-gray-900">
        <FormattedMessage {...messages?.select_timezone} />
      </div>
      <div className="w-[470px]">
        <Dropdown
          value={selectedTimezone}
          name="timezone-select"
          options={timezones}
          onChange={value => setSelectedTimezone(value)}
          placeholder="Change timezone"
          labelKey="description"
          primaryKey="value"
          id="timezones"
          isSearchable
          lightBackground
          isSearchIcon
        />
      </div>

      <div className="flex gap-3 justify-end mt-10 pr-2">
        <Button size="lg" disabled={disabled} onClick={handleClick}>
          <FormattedMessage {...messages?.submit} />
        </Button>
      </div>
    </div>
  );
};

export default TimeZones;
