/**
 *
 * NavigationIcon
 *
 */

import { faAngleRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import Avatar from 'components/base/Avatar/NewAvatar';
import React, { memo } from 'react';
// import PropTypes from 'prop-types';
import { get } from 'lodash';
import { FormattedMessage } from 'react-intl';
import Tooltip from './RightTooltip';
import messages from './messages';

const Icon = ({ icon, expanded, user }) => {
  if (icon) return <FontAwesomeIcon className={classNames('w-6 h-6 block', !expanded && 'm-auto')} icon={icon} />;

  return <Avatar data={user} size="md" isInterviewer />;
};

export function NavigationIcon(props) {
  const {
    icon,
    image,
    label,
    expanded,
    name,
    selected,
    isChevronRight,
    isNotification,
    onClick,
    item,
    additionalContent,
    customTooltipContent,
    user,
  } = props;

  return (
    <div className={classNames(!expanded && 'group relative')}>
      <div
        className={classNames(
          'flex items-center rounded-[40px] p-3 max-w-[148px] min-w-[44px] cursor-pointer',
          !selected && 'hover:bg-gray-100',
          selected && 'bg-blue-100',
          expanded ? 'w-full' : 'w-fit',
        )}
        onClick={() => onClick && onClick(item)}
        tabIndex={0}
        onKeyDown={onClick}
        role="button"
        data-testid={name}
      >
        <Tooltip text={customTooltipContent || label} expanded={expanded}>
          <div
            className={classNames(
              isNotification && 'relative',
              isNotification && "after:content-[''] after:absolute ",
              isNotification && 'after:w-2 after:h-2 after:bg-red-600 after:top-0 after:right-0 after:rounded-full',
              'text-gray-900',
            )}
          >
            <Icon image={image} icon={icon} expanded={expanded} label={label} user={user} />
          </div>
        </Tooltip>
        {expanded && (
          <div className="flex w-full justify-between ml-2 items-center">
            <div className="text-left">
              <div
                className={classNames('text-xs truncate w-[85px] text-left text-gray-900', selected && 'font-medium')}
              >
                {get(messages, label)?.id ? <FormattedMessage {...get(messages, label)} /> : label}
              </div>
              {additionalContent && <div className="text-xs truncate w-[85px]">{additionalContent}</div>}
            </div>
            {isChevronRight && <FontAwesomeIcon icon={faAngleRight} />}
          </div>
        )}
      </div>
      {/* {!expanded ? <Tooltip ref={ref} fullName={label} /> : <></>} */}
    </div>
  );
}

NavigationIcon.propTypes = {};

export default memo(NavigationIcon);
