import { takeLatest, setContext, put, call, cancel, select, takeEvery, all, take } from 'redux-saga/effects';
import * as Sentry from '@sentry/react';
import { map, concat, get } from 'lodash';

import Auth from 'utils/auth';

import fetchData from 'utils/fetchData';
import { catchError, getHasuraHeaders } from 'utils/helpers';
import { Settings } from 'luxon';
import { getDisplayRole } from 'utils/data/roles';
import { SET_TIMEZONE } from 'containers/InterviewerAvailability/constants';
import {
  GLOBAL_ERROR,
  LOGGED_IN,
  LOGIN_COMPLETE,
  CLEAR_ERROR,
  STOP_TASK,
  STOPPED_TASK,
  INIT_CLIENT,
  INIT_COMPLETE,
  STORE_USER_DATA,
  FETCH_USER_DATA,
  FETCH_META_DATA,
  UPDATE_TIME_ZONE,
} from './constants';

import { makeSelectTasks } from './selectors';
import { createEventChannel, init } from './api';
import { GET_META_DATA, GET_ORG_AUTH_USERS, GET_USER_PROFILE_DETAILS } from './queries';
import { storeAppMetaData } from './actions';
import { getDefaultTimezone } from '../InterviewerAvailability/helpers';

export function* updateTimeZone({ timezone, userId }) {
  try {
    localStorage.setItem(`wft-${userId}`, JSON.stringify(timezone));
    Settings.defaultZone = timezone?.value;
  } catch (error) {
    yield call(catchError, error);
  }
}

// Individual exports for testing
export function* loginSaga({ client /* errorCallback */ }) {
  try {
    yield setContext({ client });
    yield put({ type: LOGIN_COMPLETE, client });
  } catch (error) {
    if (process.env.NODE_ENV !== 'production' && console) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
    Sentry.captureException(error);
    yield put({ type: GLOBAL_ERROR, error });
  }
}

export function* clearErrorSaga() {
  try {
    yield call(window?.location?.replace, '/logout');
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
}

export function* stopTaskSaga({ task }) {
  try {
    if (task && task.isRunning()) {
      yield cancel(task);
    }
    yield put({ type: STOPPED_TASK, task });
  } catch (error) {
    Sentry.captureException(error);
    yield put({ type: GLOBAL_ERROR, error });
  }
}

export function* locationChangeSaga() {
  const { tasks } = yield select(makeSelectTasks(), { key: 'tasks' });

  yield all(concat(map(tasks, task => put({ type: STOP_TASK, task }))));
}

export function updateStoredData(data) {
  const userData = {
    email: data?.email,
    name: data?.name,
    tenant: { id: data?.tenant?.id, name: data?.tenant?.name },
    username: data?.username,
  };
  const storedData = JSON.parse(localStorage.getItem('tv.wft') || '{}');
  localStorage.setItem('tv.wft', JSON.stringify({ ...storedData, userData }));
}

export function* fetchUserDataSaga() {
  try {
    const data = yield call(fetchData, {
      queryString: GET_USER_PROFILE_DETAILS,
      queryKey: 'auth_user_me',
      queryVariables: {},
      forceRefresh: true,
    });
    if (data && data.id) {
      yield call(updateStoredData, data);
      yield put({ type: STORE_USER_DATA, data });
      yield put({
        type: UPDATE_TIME_ZONE,
        timezone: JSON.parse(localStorage.getItem(`wft-${data?.id}`)) || getDefaultTimezone(),
      });
    }
  } catch (error) {
    yield call(catchError, error);
    if (get(error, 'graphQLErrors.0.extensions.code') === 'invalid-jwt') {
      yield put({ type: GLOBAL_ERROR, error });
    }
  }
}

export async function handleUser({ user }) {
  let client = {};
  let u = {};
  if (user) {
    const graphToken = await user.getIdToken();
    client = await Auth.createClient({
      graphToken,
      errorCallback: () => {},
      // role: get(th, '0'),
    });
    const parsedHeaders = getHasuraHeaders(graphToken);
    const parsedTenantId = get(parsedHeaders, 'X-Hasura-Tenant-Id');
    const parsedUserId = get(parsedHeaders, 'X-Hasura-User-Id');
    const roles = get(parsedHeaders, 'X-Hasura-Allowed-Roles');
    u = {
      displayName: user.displayName,
      roles,
      currentRole: getDisplayRole(roles),
      accountName: '',
      userName: user.username,
      organization: user?.tenantId || (parsedTenantId && Number(parsedTenantId)),
      isLoggedIn: !user?.isAnonymous,
      email: user.email,
      uid: user?.uid,
      id: user?.id || (parsedUserId && Number(parsedUserId)),
    };
  }
  return {
    client,
    user: u,
  };
}

export function* initSaga() {
  let channel;
  try {
    const app = yield call(init);
    channel = yield call(createEventChannel, app);
    while (true) {
      const data = yield take(channel);
      const { user, client } = yield call(handleUser, { user: data.user });
      yield put({
        type: INIT_COMPLETE,
        user: {
          ...user,
          metadata: data?.user?.metadata,
        },
        client,
      });
      if (user && (user.email || user?.uid)) {
        yield call(Sentry.setUser, { id: user?.id || user?.email || user?.uid });
        yield all([put({ type: FETCH_USER_DATA, user: { ...user, uid: user?.uid } }), put({ type: FETCH_META_DATA })]);
      }
    }
  } catch (error) {
    yield call(catchError, error);
    yield put({ type: GLOBAL_ERROR, error });
  }
}

export function* fetchMetaDataSaga() {
  try {
    const response = yield call(fetchData, {
      queryString: GET_META_DATA,
    });
    const { auth_user } = yield call(fetchData, {
      queryString: GET_ORG_AUTH_USERS,
    });

    yield put(
      storeAppMetaData({
        response: {
          ...response,
          auth_user,
        },
      }),
    );
  } catch (error) {
    yield call(catchError, error);
    if (get(error, 'graphQLErrors.0.extensions.code') === 'invalid-jwt') {
      yield put({ type: GLOBAL_ERROR, error });
    }
  }
}

// eslint-disable-next-line no-empty-function
export default function* AppSaga() {
  yield takeLatest(INIT_CLIENT, initSaga);
  yield takeLatest(LOGGED_IN, loginSaga);
  yield takeLatest(CLEAR_ERROR, clearErrorSaga);
  yield takeLatest(FETCH_USER_DATA, fetchUserDataSaga);

  yield takeEvery(STOP_TASK, stopTaskSaga);
  yield takeLatest(FETCH_META_DATA, fetchMetaDataSaga);
  yield takeLatest([UPDATE_TIME_ZONE, SET_TIMEZONE], updateTimeZone);
}
