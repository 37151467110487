/*
 *
 * WorkflowCandidateStepDetails actions
 *
 */

import {
  INITIALIZE,
  CHANGE_WORKFLOW_STEP,
  CHANGE_CANDIDATE,
  REFETCH_WORKFLOW_CANDIDATE,
  FETCH_PREVIOUS_WORKFLOWS,
  REMOVE_PREVIOUS_WORKFLOWS,
  GENERATE_VOICE_TOKEN,
  FETCH_CANDIDATE_CALL_HISTORY,
} from './constants';

export function initialize({
  recordType,
  workflowCandidateId,
  defaultSelectedStepId,
  workflowCandidateStepId,
  defaultMeetingId,
  defaultSelectedWcsId,
  isDrive,
}) {
  return {
    type: INITIALIZE,
    recordType,
    workflowCandidateId,
    defaultSelectedStepId,
    workflowCandidateStepId,
    defaultMeetingId,
    defaultSelectedWcsId,
    isDrive,
  };
}

export function changeWorkflowStep({ payload: { selectedStep } }) {
  return {
    type: CHANGE_WORKFLOW_STEP,
    selectedStep,
  };
}

export function changeCandidateStep({ direction }) {
  return {
    type: CHANGE_CANDIDATE,
    direction,
  };
}

export function refetchWcsDetails() {
  return {
    type: REFETCH_WORKFLOW_CANDIDATE,
    forceRefresh: true,
  };
}

export function previousWorkflows() {
  return {
    type: FETCH_PREVIOUS_WORKFLOWS,
  };
}

export function removeWorkflows() {
  return {
    type: REMOVE_PREVIOUS_WORKFLOWS,
  };
}

export function generateVoiceToken() {
  return {
    type: GENERATE_VOICE_TOKEN,
  };
}

export function fetchCandidateCallHistory({ payload: { candidateId } }) {
  return {
    type: FETCH_CANDIDATE_CALL_HISTORY,
    candidateId,
  };
}
