import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the bulkSchedule state domain
 */

const selectBulkScheduleDomain = state => state?.bulkSchedule || initialState;

/**
 * Other specific selectors
 */
const selectInitialization = createSelector(selectBulkScheduleDomain, state => state?.initialized);
const selectTimezones = createSelector(selectBulkScheduleDomain, state => state?.timezones);
const selectAllEvaluators = createSelector(selectBulkScheduleDomain, state => state?.evaluators);
const selectDuration = createSelector(selectBulkScheduleDomain, state => state?.selectedDuration);
const selectSlots = createSelector(selectBulkScheduleDomain, state => state?.slots);
const selectSlotsList = createSelector(selectBulkScheduleDomain, state => state?.slots && Object.values(state?.slots));
const selectSelectedEvaluators = createSelector(selectBulkScheduleDomain, state => state?.selectedEvaluators);
const selectCalendarAvailabilitiesBySlot = createSelector(
  selectBulkScheduleDomain,
  state => state?.calendarAvailabilitiesBySlot,
);

/**
 * Default selector used by BulkSchedule
 */

const makeSelectBulkSchedule = () => createSelector(selectBulkScheduleDomain, subState => subState);

export default makeSelectBulkSchedule;
export {
  selectBulkScheduleDomain,
  selectTimezones,
  selectAllEvaluators,
  selectInitialization,
  selectDuration,
  selectSlots,
  selectSlotsList,
  selectSelectedEvaluators,
  selectCalendarAvailabilitiesBySlot,
};
