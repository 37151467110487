/*
 *
 * BulkSchedule reducer
 *
 */
import { produce } from 'immer';
import {
  ADD_BULK_SLOTS,
  ADD_SELECTED_CANDIDATE,
  ADD_SELECTED_EVALUATOR,
  INITIALIZATION_COMPLETED,
  INITIALIZE,
  REMOVE_SLOT,
  STORE_CALENDAR_SCHEDULE,
  STORE_FETCHED_EVALUATORS,
  STORE_FETCHED_PROVIDER,
  STORE_FETCHED_TIMEZONES,
  STORE_FILE_UPLOAD_DATA,
  STORE_UPDATED_SLOT,
  UPDATE_BULK_SLOTS,
  UPDATE_SELECTED_DURATION,
} from './constants';

export const initialState = {
  initialized: false,
  providers: [],
  timezones: [],
  evaluators: {},
  selectedCandidates: {},
  selectedEvaluators: {},
  selectedDuration: {
    value: 30,
    label: '30 Mins',
    duration: '00:30:00',
  },
  slots: {},
  calendarAvailabilitiesBySlot: {},
};

/* eslint-disable default-case, no-param-reassign */
const bulkScheduleReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case INITIALIZE: {
        Object.keys(initialState).forEach(key => {
          draft[key] = initialState[key];
        });
        break;
      }
      case INITIALIZATION_COMPLETED: {
        draft.initialized = true;
        break;
      }
      case STORE_FETCHED_EVALUATORS: {
        draft.evaluators = action.payload;
        break;
      }
      case STORE_FETCHED_TIMEZONES: {
        draft.timezones = action.payload;
        break;
      }
      case STORE_FETCHED_PROVIDER: {
        draft.providers = action.payload;
        break;
      }
      case ADD_SELECTED_EVALUATOR: {
        draft.selectedEvaluators = action.payload;
        break;
      }
      case ADD_SELECTED_CANDIDATE: {
        draft.selectedCandidates = action.payload;
        break;
      }
      case ADD_BULK_SLOTS: {
        draft.slots = action.payload;
        break;
      }
      case UPDATE_SELECTED_DURATION: {
        draft.selectedDuration = action.payload;
        break;
      }
      case STORE_FILE_UPLOAD_DATA:
      case UPDATE_BULK_SLOTS:
      case STORE_UPDATED_SLOT: {
        if (action.payload && Object.keys(action.payload).length) {
          Object.keys(action.payload).forEach(key => {
            draft.slots[key] = action.payload[key];
          });
        }
        break;
      }
      case REMOVE_SLOT: {
        if (action?.slotId && draft.slots[action?.slotId]) {
          delete draft.slots[action?.slotId];
        }
        break;
      }
      case STORE_CALENDAR_SCHEDULE: {
        if (action.payload && Object.keys(action.payload).length) {
          Object.keys(action.payload).forEach(key => {
            draft.calendarAvailabilitiesBySlot[key] = action.payload[key];
          });
        }
        break;
      }
    }
  });

export default bulkScheduleReducer;
