/**
 *
 * AvatarGroup
 *
 */

import React, { memo, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { slice } from 'lodash';

import classNames from 'classnames';
import Avatar from '../Avatar';
import Tooltip from './Tooltip';

const sizes = {
  xs: '-space-x-1',
  sm: '-space-x-2',
  md: '-space-x-2',
  lg: '-space-x-3',
  xl: '-space-x-3',
};

export function AvatarGroup(props) {
  const { users = [], limit = 0, size, avatarProps } = props;

  const ref = useRef();
  const [showTooltip, setShowTooltip] = useState(false);

  const showCount = useMemo(() => !!(limit && users.length > limit), [limit, users]);

  const customInitial = useMemo(() => `+${users.length - limit}`, [users, limit]);

  const data = useMemo(() => {
    if (limit) {
      return slice(users, 0, limit);
    }
    return users;
  }, [users, limit]);

  return (
    <div className={classNames('flex overflow-hidden', sizes[size])}>
      <div
        className={classNames('flex overflow-hidden', sizes[size])}
        id="avatar-group"
        ref={ref}
        onMouseEnter={() => setShowTooltip(() => true)}
        onMouseLeave={() => setShowTooltip(() => false)}
        onFocus={() => setShowTooltip(() => true)}
      >
        {data.map(user => (
          <Avatar data={user} className="ring-1 ring-white" key={user.id} size={size} {...avatarProps} showRing />
        ))}
        {showCount ? (
          <Avatar
            className="ring-1 ring-white"
            size={size}
            customInitial={customInitial}
            data={{}}
            {...avatarProps}
            showToolTip={false}
            showRing
          />
        ) : (
          <></>
        )}
        {showTooltip && <Tooltip ref={ref} data={users} />}
      </div>
    </div>
  );
}

AvatarGroup.propTypes = {
  users: PropTypes.array,
  limit: PropTypes.number,
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
  avatarProps: PropTypes.shape({
    size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
    isInitials: PropTypes.bool,
    showToolTip: PropTypes.bool,
  }),
};

Avatar.defaultProps = {
  users: [],
  limit: 5,
  size: 'sm',
};

export default memo(AvatarGroup);
