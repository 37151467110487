export const mixpanelEvents = {
  SELF_SCHEDULE_CLICK: 'meeting_scheduled_self',
  STATIC_SCHEDULE_CLICK: 'meeting_scheduled_static',
  SELF_SCHEDULE_MODAL_CLICK: 'self_schedule_modal_click',
  PROCEED_CANCEL_CLICK_MODAL: 'proceed_click_cancel_modal',
  PROCEED_CANCEL_SIDE_PANEL: 'proceed_cancel_side_panel',
  NO_CLICK_CANCEL_INT_MODAL: 'no_click_cancel_int_modal',
  NO_CANCEL_CLICK_SIDE_PANEL: 'no_cancel_click_side_panel',
  CANCEL_SIDE_PANEL_OPEN: 'cancel_side_panel_open',
  CANCEL_MODAL_OPEN: 'meeting_scheduled_cancel_start',
  RESCHEDULE_CLICK_SIDE_PANEL: 'reschedule_click_side_panel',
  PROCEED_CLICK_RESCHEDULE: 'meeting_rescheduled',
  UPLOAD_CLICK_SIDE_PANEL: 'candidate_uploaded',
  SCHEDULE_CLICK_DASHBOARD: 'schedule_click_dashboard',
  SCHEDULE_CLICK_SCHEDULE_SUCCESS: 'schedule_click_schedule_success',
  MEETING_INTERVIEWER_DECLINED_STARTED: 'meeting_interviewer_declined_started',
  MEETING_INTERVIEWER_DECLINED_COMPLETED: 'meeting_interviewer_declined_completed',
  PROPOSE_NEW_TIME_EMAIL_INTERVIEWER: 'meeting_interviewer_counter_new_time',
  INTERVIEWER_DECLINED_CANDIDATE_PROPOSED_TIME: 'meeting_interviewer_rejected_candidate_proposed_time',
  INTERVIEWER_ACCEPT_CANDIDATE_PROPOSED_TIME: 'meeting_interviewer_accepted_candidate_proposed_time',
  INTERVIEWER_JOINED_MEETING: 'meeting_participant_joined',
  INTERVIEWER_ADDED_SLOT: 'interviewer_added_slot',
  INTERVIEWER_DELETED_SLOT: 'interviewer_deleted_slot',
  INTERVIEWER_CREATED_WORKFLOW: 'recruiter_created_workflow',
  INTERVIEWER_VIEWED_WORKFLOW: 'recruiter_viewed_workflow_details',
  INTERVIEWER_SET_AVAILABILITY_VIEW: 'interviewer_availability_dashboard_opened',
  INTERVIEWER_NAVIGATED_CALENDAR_VIEW: 'interviewer_navigated_calendar_view',
  INTERVIEWER_ADDING_OVERRIDE_SLOT: 'interviewer_adding_override_slot',
  INTERVIEWER_ADDED_OVERRIDE_SLOT: 'interviewer_added_override_slot',
  INTERVIEWER_ADDED_HOLIDAY: 'interviewer_added_holiday',
  RECRUITER_BEGIN_WORKFLOW_CREATION: 'recruiter_begin_workflow_creation',
  INTERVIEWER_EDITED_TIME_SLOT: 'interviewer_edited_time_slot',
  RECRUITER_SELECTED_WORKFLOW_TEMPLATE: 'recruiter_selected_workflow_template',
  INTERVIEWER_COPIED_SLOT: 'interviewer_copied_slot',
  RECRUITER_RENAMED_WORKFLOW_TEMPLATE: 'recruiter_renamed_workflow_template',
  MEETIING_INTERVIEWER_EMAIL_INVITE_JOINED: 'meeting_interviewer_email_invite_joined',
};
