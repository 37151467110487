/**
 *
 * Breadcrumb
 *
 */

import React, { memo, useContext } from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-light-svg-icons';
import { useNavigate } from 'react-router';
import ApplicationContext from 'contexts/ApplicationContext';

export function Breadcrumb(props) {
  const { isStory, defaultItems } = props;
  const { breadcrumbState: items } = useContext(ApplicationContext);
  const navigate = useNavigate();

  const navigateToPage = async item => {
    navigate(item.href);
  };

  const breadCrumbItems = isStory ? defaultItems : items;
  if (!breadCrumbItems?.length) return null;
  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol className="flex items-center lg:space-x-1">
        {breadCrumbItems?.map((item, index) => (
          <li key={item.key} className="flex items-center">
            <div className="flex items-center">
              {index !== 0 && <FontAwesomeIcon icon={faChevronRight} />}{' '}
              <span
                href={item.href}
                className={classNames(
                  'text-sm py-1.5 px-2 mr-2',
                  breadCrumbItems[breadCrumbItems.length - 1]?.key === item.key
                    ? 'text-gray-700 font-medium'
                    : 'text-gray-400 cursor-pointer rounded ' +
                        'hover:text-gray-700 hover:bg-gray-100 border border-transparent hover:border-gray-200',
                )}
                onClick={() => breadCrumbItems[breadCrumbItems.length - 1]?.key !== item.key && navigateToPage(item)}
                onKeyDown={() => breadCrumbItems[breadCrumbItems.length - 1]?.key !== item.key && navigateToPage(item)}
                tabIndex={0}
                role="link"
              >
                {item.label}
              </span>
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
}

export default memo(Breadcrumb);
