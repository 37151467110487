/*
 *
 * InterviewerAvailability actions
 *
 */

import { FETCH_AVAILABILITIES, SET_TIMEZONE } from './constants';

export const fetchAvailabilities = () => ({
  type: FETCH_AVAILABILITIES,
});

export const changeTimezone = ({ timezone, userId }) => ({
  type: SET_TIMEZONE,
  timezone,
  userId,
});
