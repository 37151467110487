/*
 *
 * NewWorkflowDetails constants
 *
 */

export const INITIALIZE = 'app/NewWorkflowDetails/INITIALIZE';

export const RE_FETCH_ALL_WORKFLOW_DATA = 'app/NewWorkflowDetails/RE_FETCH_ALL_WORKFLOW_DATA';
export const STORE_WORKFLOW_DATA = 'app/NewWorkflowDetails/STORE_WORKFLOW_DATA';
export const STORE_WORKFLOW_META_DATA = 'app/NewWorkflowDetails/STORE_WORKFLOW_META_DATA';

export const STORE_WORKFLOW_CANDIDATES_DATA = 'app/NewWorkflowDetails/STORE_WORKFLOW_CANDIDATES_DATA';
export const STORE_WORKFLOW_CANDIDATE_STEPS_DATA = 'app/NewWorkflowDetails/STORE_WORKFLOW_CANDIDATE_STEPS_DATA';

export const STORE_TAB = 'app/NewWorkflowDetails/STORE_TAB';
export const UPDATE_SELECTED_TAB = 'app/NewWorkflowDetails/UPDATE_SELECTED_TAB';
export const STORE_LATEST_WORKFLOW_CANDIDATES_DATA = 'app/NewWorkflowDetails/STORE_LATEST_WORKFLOW_CANDIDATES_DATA';
export const SUBSCRIBE = 'app/NewWorkflowDetails/SUBSCRIBE';
export const UNSUBSCRIBE = 'app/NewWorkflowDetails/UNSUBSCRIBE';
export const STORE_SUBSCRIPTION_MEETING_DATA = 'app/NewWorkflowDetails/STORE_SUBSCRIPTION_MEETING_DATA';
