/**
 *
 * SideNavigation
 *
 */

import classNames from 'classnames';
import { ReportIssue } from 'components/base/SideNavigation/ReportIssue';
import { AppNotifications } from 'containers/AppNotifications';
import { ActionProvider } from 'contexts/ActionContext';
import React, { useContext, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/pro-regular-svg-icons';
import Logo from 'images/logo/logo_with_name.svg';
import ApplicationContext from 'contexts/ApplicationContext';
import Auth from 'utils/auth';
import {
  // ROLE_MASTER_RECRUITER,
  // ROLE_RECRUITER,
  ROLE_TENANT_ADMIN,
  ROLE_CONTENT_MANAGER,
  ROLE_MASTER_CONTENT_MANAGER,
} from 'utils/data/roles';
import { NavModules } from './NavModules';
import { ProfilePopover } from './ProfilePopover';
import { MoreApps } from './MoreApps';
import { GetHelp } from './GetHelp';
import RequestFeature from './RequestFeature';
import AccountSettingsContainer from './AccountSettingsContainer';

export function SideNavigation() {
  const { sidebarOpen, setSidebarOpen } = useContext(ApplicationContext);
  const [openReportIssue, toggleReportIssue] = useState(false);
  const [requestFeature, toggleRequestFeature] = useState(false);
  const [accountSettings, toggleAccountSettings] = useState(false);

  const hideReportIssue = () => {
    toggleReportIssue(false);
  };
  const handleReportSuccess = () => {
    toggleReportIssue(false);
  };

  const showReportIssue = () => {
    toggleReportIssue(true);
  };

  const hideRequestFeature = () => {
    toggleRequestFeature(false);
  };

  const showRequestFeature = () => {
    toggleRequestFeature(true);
  };

  const showMoreApps = useMemo(() => {
    const roles = Auth.roles || [];
    const allowedRoles = [
      // ROLE_MASTER_RECRUITER,
      // ROLE_RECRUITER,
      ROLE_TENANT_ADMIN,
      ROLE_CONTENT_MANAGER,
      ROLE_MASTER_CONTENT_MANAGER,
    ];
    return roles.some(role => allowedRoles.includes(role));
  }, [Auth]);
  const showAccountSettings = () => {
    toggleAccountSettings(true);
  };

  const hideAccountSettings = () => {
    toggleAccountSettings(false);
  };

  return (
    <>
      <div
        className={classNames(
          'flex flex-col justify-between min-h-full px-3',
          sidebarOpen ? 'w-44' : 'w-[70px]',
          'border-r border-gray-200 border-t border-t-gray-100',
          'transition-[width] duration-150',
          'bg-white',
        )}
      >
        <div className="mt-3">
          <div className="flex items-center">
            <div>
              <FontAwesomeIcon
                icon={faBars}
                className={classNames(
                  sidebarOpen ? 'bg-blue-100' : 'hover:bg-gray-100',
                  'h-6 w-6 text-gray-500 rounded-full hover:bg-gray-100 p-3 block cursor-pointer',
                )}
                aria-hidden="true"
                onClick={() => setSidebarOpen(prev => !prev)}
                data-testid="hamburger-icon"
              />
            </div>

            {sidebarOpen && (
              <div className="flex items-center ml-4">
                <img className="h-8 w-auto" src={Logo} alt="Talview" />
              </div>
            )}
          </div>
          <div>
            <NavModules expanded={sidebarOpen} />
          </div>
          {showMoreApps && (
            <div className="mt-4">
              <MoreApps expanded={sidebarOpen} />
            </div>
          )}
        </div>

        <div className="pt-5 border-t">
          <GetHelp
            expanded={sidebarOpen}
            openReportIssue={openReportIssue}
            toggleReportIssue={toggleReportIssue}
            hideReportIssue={hideReportIssue}
            handleReportSuccess={handleReportSuccess}
            showReportIssue={showReportIssue}
            requestFeature={requestFeature}
            hideRequestFeature={hideRequestFeature}
            showRequestFeature={showRequestFeature}
          />
          <div className="relative hidden">
            <AppNotifications expanded={sidebarOpen} />
          </div>
          <div>
            <ProfilePopover
              expanded={sidebarOpen}
              showAccountSettings={showAccountSettings}
              accountSettings={accountSettings}
            />
          </div>
        </div>
      </div>
      {openReportIssue && (
        <ActionProvider>
          <ReportIssue open handleClose={hideReportIssue} handleOnSuccess={handleReportSuccess} />
        </ActionProvider>
      )}

      {requestFeature && (
        <ActionProvider>
          <RequestFeature open handleClose={hideRequestFeature} />
        </ActionProvider>
      )}

      {accountSettings && <AccountSettingsContainer open handleClose={hideAccountSettings} />}
    </>
  );
}

export default SideNavigation;
