/*
 * ProfileModal Messages
 *
 * This contains all the text for the ProfileModal component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.ProfileModal';

export default defineMessages({
  view_profile: {
    id: `${scope}.view_profile`,
    defaultMessage: 'View Profile',
  },
  logout: {
    id: `${scope}.logout`,
    defaultMessage: 'Logout',
  },
  'Tenant Admin': {
    id: `${scope}.tenant_admin`,
    defaultMessage: 'Tenant Admin',
  },
  Admin: {
    id: `${scope}.admin`,
    defaultMessage: 'Admin',
  },
  Recruiter: {
    id: `${scope}.recruiter`,
    defaultMessage: 'Recruiter',
  },
  'Master Recruiter': {
    id: `${scope}.master_recruiter`,
    defaultMessage: 'Master Recruiter',
  },
  Evaluator: {
    id: `${scope}.evaluator`,
    defaultMessage: 'Evaluator',
  },
  'Content Manager': {
    id: `${scope}.content_manager`,
    defaultMessage: 'Content Manager',
  },
  Collaborator: {
    id: `${scope}.collaborator`,
    defaultMessage: 'Collaborator',
  },
  'Hiring Manager': {
    id: `${scope}.hiring_manager`,
    defaultMessage: 'Hiring Manager',
  },
  'Master Content Manager': {
    id: `${scope}.master_content_manager`,
    defaultMessage: 'Master Content Manager',
  },
});
