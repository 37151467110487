import { changeLocale } from 'containers/LanguageProvider/actions';
import { selectSelectedLanguage } from 'containers/LanguageProvider/selectors';
import React, { useState } from 'react';
import { find, get } from 'lodash';
import { Dropdown } from 'components/Dropdown';
import Button from 'components/base/Button/newButton';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { languages } from './languages';
import messages from './messages';

const Content = () => {
  const selectedLanguage = useSelector(selectSelectedLanguage);
  const dispatch = useDispatch();
  const [language, setLanguage] = useState(() => find(languages, ['value', selectedLanguage]));
  const save = () => {
    localStorage.setItem('language', JSON.stringify(language));
    dispatch(changeLocale(language.value));
  };

  const formatOptionLabel = option => (
    <div className="flex items-center">
      <span>
        <img src={get(option, 'flag')} alt="flag" className="h-7 w-7 mr-2" />
      </span>
      <span>{get(option, 'label')}</span>
    </div>
  );
  return (
    <div className="w-full">
      <div className="mb-4 font-normal leading-4 text-sm text-gray-900">
        <FormattedMessage {...messages?.change_lang} />
      </div>
      <div className="w-[470px]">
        <Dropdown
          value={language}
          name="provider-select"
          options={languages}
          onChange={value => setLanguage(value)}
          placeholder="Change Language"
          labelKey="flag"
          primaryKey="value"
          id="topics"
          customFormatOptionLabel={formatOptionLabel}
          lightBackground
        />
      </div>

      <div className="flex gap-3 justify-end pt-10 pr-2">
        {/* <Button type="tertiary" size="lg" onClick={handleClose}>
          <FormattedMessage {...messages?.cancel} />
        </Button> */}
        <Button size="lg" disabled={language?.value === selectedLanguage} onClick={save}>
          <FormattedMessage {...messages?.submit} />
        </Button>
      </div>
    </div>
  );
};

export default Content;
